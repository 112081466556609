import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create an Axios instance - KS
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MYAIDA_SERVERLESS_API_HOST, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to inject token into headers before each request
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken"); // Fetch token from storage or other source
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      // The request was made and the server responded with a status code
      console.log("Error status code:", error.response.status); // Access the status code
      console.log("Error data:", error.response.data); // Optionally log the error data
      return Promise.reject({
        status: error.response.status,
        data: error.response.data,
      });
    } else if (error.request) {
      // The request was made but no response was received
      console.log("No response received:", error.request);
      return Promise.reject({
        status: null,
        message: "SomeThing went Wrong",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error message:", error.message);
      return Promise.reject({
        status: null,
        message: error.message,
      });
    }
  }
);

const logout = async () => {
  localStorage.clear();
  delete axiosInstance.defaults.headers.common.Authorization;
  toast.warning("Session Expired.Please login again!");
  setTimeout(() => {
    window.location.href = "/";
  }, 5000);
};

export default axiosInstance;
