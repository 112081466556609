import { ArrowBack, Chat, Note, StickyNote2 } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppDrawer from "../../Layout/AppDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  findDuration,
  FormatArray,
  removeLastCommaAndWord,
  splitDateTime,
  SubFormatArray,
  ChildOfSubFormatArray,
  GrandChildOfSubFormatArray,
} from "../../Utils/utils";
import { getData } from "../../API/apiService";
import { useAuth } from "../../customHooks/AuthContext";
import CachedIcon from "@mui/icons-material/Cached";
import SessionFeedback from "./SessionFeedback";
import {
  sessionAutoRefreshTiming,
  sessionReportMaxAttemptCount,
} from "../../Utils/constant";
import React from "react";

import {
  TreatmentPlanPharmacologicalTable,
  TreatmentPlanNonPharmacologicalTable,
} from "./TreatmentPlanPharmacologicalTable";

interface DataItem {
  [key: string]: string[];
}

type SummaryEntry = [string, Record<string, any>];

interface SessionDignosisType {
  disorder: string;
  code: string;
  severity: string;
}

export const sessionReportTitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#fff6d1",
  width: "100%",
  padding: "0.5em",
};

export const dignosisReportSubtitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#fff6d1",
  width: "fit-content",
  borderRadius: "4px",
  padding: "0.3em",
};

export const sessionReportSubtitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#ffe478",
  width: "fit-content",
  borderRadius: "4px",
  padding: "0.3em",
};

export const sessionReportSummaryReportTitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#f1f1f1",
  width: "fit-content",
  padding: "0.5em",
};

// const staticSummaryAPIResponseFormat = [
//   ["CRSWD", "Yes"],
//   ["HYPERSOMNOLENCE", "No"],
//   ["INSOMNIA", "No"],
//   ["PTSD", "Yes"],
//   ["GAD", "No"],
//   ["MMD", "No"],
//   [
//     "summary_gad",
//     {
//       "1_gad_symptom": [
//         "No",
//         "There is no mention of the patient experiencing excessive anxiety and worry for at least 6 months in the conversation.",
//       ],
//       "2_gad_physical_symptoms": [
//         "No",
//         "There is no mention of the patient experiencing physical symptoms related to anxiety in the conversation.",
//       ],
//       "3_gad_control_of_worry": [
//         "No",
//         "There is no mention of the patient having difficulty controlling worry in the conversation.",
//       ],
//       "4_gad_impairment": [
//         "No",
//         "There is no mention of the symptoms causing significant distress or impairment in social, occupational, or other important areas of functioning in the conversation.",
//       ],
//       "5_gad_exclusion": [
//         "No",
//         "There is no information provided to determine if the symptoms are better explained by other conditions.",
//       ],
//       "5a_gad_panic_exclusion": [
//         "No",
//         "There is no mention of the patient experiencing panic attacks in the conversation.",
//       ],
//       "5b_gad_social_anxiety_exclusion": [
//         "No",
//         "There is no mention of the patient's anxiety primarily focusing on social situations in the conversation.",
//       ],
//       "5c_gad_ocd_exclusion": [
//         "No",
//         "There is no mention of the patient's anxiety involving persistent, unwanted, and intrusive thoughts or repetitive behaviors in the conversation.",
//       ],
//       "5d_gad_medical_exclusion": [
//         "No",
//         "There is no mention of the symptoms being attributable to substance use, medication, or another medical condition in the conversation.",
//       ],
//       "6a_gad_severity_mild": [
//         "No",
//         "There is no information provided to determine the severity of the symptoms.",
//       ],
//       "6b_gad_severity_moderate": [
//         "No",
//         "There is no information provided to determine the severity of the symptoms.",
//       ],
//       "6c_gad_severity_severe": [
//         "No",
//         "There is no information provided to determine the severity of the symptoms.",
//       ],
//     },
//   ],
//   [
//     "summary_general",
//     {
//       medium: [
//         "In-person visit",
//         "The transcript suggests an in-person conversation between the patient and the doctor at a clinic.",
//       ],
//       age: [],
//       past_medical_history: [
//         "The patient was diagnosed with high blood pressure about three years ago, but it has been well-controlled with diet changes and monitoring.",
//       ],
//       past_psychiatric_history: [],
//       "past psychiatric_medication": [],
//       cognitive_history: [],
//       cognitive_functioning: [],
//       legal_problem: [],
//       liver_problem: [],
//       heart_problem: [],
//       vitamin_deficiency: [],
//       infection: [],
//       suicide_attempt: [],
//       suicide_ideation: [],
//       overdose: [],
//       violence: [],
//       child_trauma: [],
//       relationship: ["The patient is married."],
//       gender_issue: [],
//       firearm: [],
//       finance: [],
//       friends: [],
//       safety: [],
//       education: [],
//       employment: [],
//       mental_health: [],
//       other_mental_health: [],
//       vocabulary: [
//         "The patient's vocabulary and choice of words do not suggest any particular personality traits or concerns.",
//       ],
//       framework: [],
//       diagnosis: [],
//       treatment: [],
//       secondary_diagnosis: [],
//       reaction: [],
//       insurance: [
//         "The patient expressed concern about their recent change in insurance company and whether the visit would be covered.",
//       ],
//       pregnancy: ["The patient mentioned being in menopause."],
//       other: [],
//       "": [],
//       sleep_pattern: [],
//       physical_activity: [],
//       daily_routine: [],
//       diet_and_nutrition: [],
//       stress_level: [],
//       trigger: [],
//       energy_level: [],
//       hobbies_and_interests: [],
//       self_perception_and_confidence: [],
//       self_care: [],
//       support_system: [],
//       thyroid_problem: [],
//       adrenal_problem: [],
//       neurological_problem: [],
//       inflammatory_autoimmune_problem: [],
//       hormonal_imbalances: [],
//       gastrointestinal_problem: [],
//       diabetes_mellitus: [],
//       sex: [],
//       allergies: ["The patient reported no drug allergies."],
//       patient_category: ["Mental"],
//       visit_scenario: ["Acute complaint"],
//       chief_complaint: [
//         "The patient's chief complaint is a severe headache that started three days ago.",
//       ],
//       history_of_present_illness: [],
//       'The headache started about three days ago and nothing has helped it.",':
//         [
//           "The headache started about three days ago and nothing has helped it.",
//         ],
//       "It's a constant, severe (10/10) pain all over the head, worse with movement and light.\",":
//         [
//           "It's a constant, severe (10/10) pain all over the head, worse with movement and light.",
//         ],
//       'The patient has been unable to go to work due to the headache.",': [
//         "The patient has been unable to go to work due to the headache.",
//       ],
//       "The patient has experienced nausea and vomiting due to the headache.": [
//         "The patient has experienced nausea and vomiting due to the headache.",
//       ],
//       "],": [],
//       family_history_medical: [
//         "The patient's mother used to get migraines when she was younger.",
//       ],
//       review_of_systems: [],
//       medication_review: [
//         "The patient has tried Tylenol and Motrin for the headache, but they did not help.",
//       ],
//       physical_examination: [],
//       assessment: [],
//       plan: [
//         "The doctor plans to complete a physical exam and do some testing for the headache.",
//       ],
//       interval_history: [],
//       lab_work: [],
//       recent_lab_work: [],
//       health_maintenance_review: [],
//       risk_factor_assessment: [],
//       growth_and_development: [],
//       immunization_status: [],
//       functional_status: [],
//     },
//   ],
//   [
//     "summary_induced",
//     {
//       "1_intoxication": [
//         "No",
//         "There is no mention of the patient recently using any substances within a timeframe consistent with acute intoxication.",
//       ],
//       "2_intoxication": [
//         "No",
//         "There is no evidence of significant maladaptive behavioral or psychological changes after recent substance use.",
//       ],
//       "3_intoxication": [
//         "No",
//         "The patient does not exhibit at least two of the listed symptoms immediately after recent substance use.",
//       ],
//       "4_intoxication": [
//         "No",
//         "There is no indication that the symptoms are attributable to another medical condition or mental disorder.",
//       ],
//       "1_induced_anxiety": [
//         "No",
//         "The patient does not report experiencing panic attacks or anxiety.",
//       ],
//       "2_induced_anxiety": [
//         "No",
//         "Not applicable as the patient does not have panic attacks or anxiety.",
//       ],
//       "3_induced_anxiety": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_anxiety": [
//         "No",
//         "Symptoms are not better explained by an anxiety disorder that is not substance-induced.",
//       ],
//       "1_induced_bipolar": [
//         "No",
//         "There is no mention of the patient experiencing a persistent disturbance in mood characterized by elevated, expansive, or irritable mood.",
//       ],
//       "2_induced_bipolar": [
//         "No",
//         "Not applicable as the patient does not have a mood disturbance.",
//       ],
//       "3_induced_bipolar": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_bipolar": [
//         "No",
//         "Symptoms are not better explained by a bipolar disorder that is not substance-induced.",
//       ],
//       "1_induced_delirium": [
//         "No",
//         "There is no mention of the patient experiencing a disturbance in attention and awareness that developed over a short period and fluctuates in severity during the day.",
//       ],
//       "2_induced_delirium": [
//         "No",
//         "There is no mention of the patient experiencing additional cognitive disturbances characteristic of delirium.",
//       ],
//       "3_induced_delirium": [
//         "No",
//         "There is no evidence that the disturbance is a direct physiological consequence of sustained substance use.",
//       ],
//       "4_induced_delirium": [
//         "No",
//         "Symptoms do not occur in the context of a severely reduced level of arousal, such as coma.",
//       ],
//       "1_induced_mdd": [
//         "No",
//         "There is no mention of the patient experiencing a persistent disturbance in mood characterized by depressed mood or markedly diminished interest or pleasure in activities.",
//       ],
//       "2_induced_mdd": [
//         "No",
//         "Not applicable as the patient does not have a mood disturbance.",
//       ],
//       "3_induced_mdd": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_mdd": [
//         "No",
//         "Symptoms are not better explained by a depressive disorder that is not substance-induced.",
//       ],
//       "0_induced_nc": [
//         "No",
//         "There is no evidence of modest cognitive decline from a previous level of performance in one or more cognitive domains.",
//       ],
//       "1_induced_nc": [
//         "No",
//         "There is no evidence of significant cognitive decline from a previous level of performance in one or more cognitive domains.",
//       ],
//       "1_1_induced_nc": [
//         "No",
//         "The patient does not exhibit amnestic-confabulatory symptoms, meaning fabricating details due to loss of memory.",
//       ],
//       "2_induced_nc": [
//         "No",
//         "Not applicable as there is no cognitive decline.",
//       ],
//       "3_induced_nc": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_nc": [
//         "No",
//         "Symptoms are not better explained by a neurocognitive disorder that is not substance-induced.",
//       ],
//       "1_induced_ocd": [
//         "No",
//         "The patient does not report obsessions, compulsions, skin picking, hair pulling, other body-focused repetitive behaviors, or other symptoms characteristic of obsessive-compulsive and related disorders.",
//       ],
//       "2_induced_ocd": [
//         "No",
//         "Not applicable as the patient does not exhibit OCD symptoms.",
//       ],
//       "3_induced_ocd": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_ocd": [
//         "No",
//         "Symptoms are not better explained by an obsessive-compulsive disorder that is not substance-induced.",
//       ],
//       "1_induced_psychotic": [
//         "No",
//         "There is no mention of the patient having hallucinations or delusions in excess of those typically associated with substance use.",
//       ],
//       "2_induced_psychotic": [
//         "No",
//         "Not applicable as the patient does not have psychotic symptoms.",
//       ],
//       "3_induced_psychotic": [
//         "No",
//         "There is no mention of the patient experiencing significant distress or impairment in social, occupational, or other important areas of functioning.",
//       ],
//       "4_induced_psychotic": [
//         "No",
//         "Symptoms are not better explained by a primary psychotic disorder.",
//       ],
//       "1_induced_sleep": [
//         "Yes",
//         "The patient reports a prominent and severe disturbance in sleep, as they mention not being able to go to work due to the headache.",
//       ],
//       "2_induced_sleep": [
//         "No",
//         "There is no mention of the patient using substances that could cause the sleep disturbance.",
//       ],
//       "3_induced_sleep": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_sleep": [
//         "No",
//         "Symptoms are not better explained by a sleep disorder that is not substance-induced.",
//       ],
//       "1_induced_sex": [
//         "No",
//         "There is no mention of the patient experiencing a significant disturbance in sexual function.",
//       ],
//       "2_induced_sex": [
//         "No",
//         "Not applicable as the patient does not report a disturbance in sexual function.",
//       ],
//       "3_induced_sex": [
//         "No",
//         "There is no mention of the patient experiencing a delirium.",
//       ],
//       "4_induced_sex": [
//         "No",
//         "Symptoms are not better explained by a sexual dysfunction that is not substance-induced.",
//       ],
//     },
//   ],
//   [
//     "summary_mdd",
//     {
//       "1_mdd_mood": [
//         "No",
//         "There is no mention of the patient feeling sad or empty most of the day in the conversation.",
//       ],
//       "2_mdd_interest": [
//         "No",
//         "There is no information provided about the patient losing interest or pleasure in activities they used to enjoy.",
//       ],
//       "3_mdd_weight": [
//         "No",
//         "There is no mention of the patient experiencing significant weight loss, weight gain, or changes in appetite.",
//       ],
//       "4_mdd_insomnia": [
//         "No",
//         "There is no information provided about the patient having trouble sleeping or sleeping too much.",
//       ],
//       "5_mdd_retardation": [
//         "No",
//         "There is no mention of the patient feeling restless or slowed down in a noticeable way.",
//       ],
//       "6_mdd_fatigue": [
//         "No",
//         "There is no information provided about the patient feeling tired or having a loss of energy.",
//       ],
//       "7_mdd_guilt": [
//         "No",
//         "There is no mention of the patient experiencing feelings of worthlessness or excessive or inappropriate guilt.",
//       ],
//       "8_mdd_concentration": [
//         "No",
//         "There is no information provided about the patient having trouble thinking, concentrating, or making decisions.",
//       ],
//       "9_mdd_suicide": [
//         "No",
//         "There is no mention of the patient having recurrent thoughts of death or suicide.",
//       ],
//       "10_mdd_symptom_count": [
//         "No",
//         "There is no indication that the patient has experienced at least five of the core symptoms, including either a depressed mood or loss of interest/pleasure, for at least two weeks.",
//       ],
//       "11_mdd_impairment": [
//         "No",
//         "There is no information provided about the patient's symptoms causing significant distress or impairment in their daily functioning.",
//       ],
//       "12_mdd_exclusion_medical": [
//         "Yes",
//         "There is no mention of the patient's symptoms being attributable to substance use, medication, or another medical condition.",
//       ],
//       "13_mdd_exclusion_schizo": [
//         "Yes",
//         "There is no evidence suggesting that the patient's symptoms are better accounted for by a schizophrenia spectrum or other psychotic disorder.",
//       ],
//       "14_mdd_hypomanic": [
//         "Yes",
//         "There is no mention of the patient ever having a manic or hypomanic episode.",
//       ],
//       "15_mdd_episode_type": [
//         "No",
//         "There is no information provided about whether this is a recurrent episode or a single episode.",
//       ],
//       "16a_mdd_severity_mild": [
//         "No",
//         "There is no information provided about the severity of the patient's symptoms.",
//       ],
//       "16b_mdd_severity_moderate": [
//         "No",
//         "There is no information provided about the severity of the patient's symptoms.",
//       ],
//       "16c_mdd_severity_severe": [
//         "No",
//         "There is no information provided about the severity of the patient's symptoms.",
//       ],
//       "17a_mdd_remission_partial": [
//         "No",
//         "There is no information provided about the patient being in partial remission.",
//       ],
//       "17b_mdd_remission_full": [
//         "No",
//         "There is no information provided about the patient being in full remission.",
//       ],
//       "18_mdd_distress": [
//         "No",
//         "There is no mention of the patient exhibiting anxious distress.",
//       ],
//       "19_mdd_mixed": [
//         "No",
//         "There is no information provided about the patient exhibiting mixed features.",
//       ],
//       "20_mdd_melancholic": [
//         "No",
//         "There is no mention of the patient demonstrating melancholic symptoms.",
//       ],
//       "21_mdd_atypical": [
//         "No",
//         "There is no information provided about the patient exhibiting atypical symptoms.",
//       ],
//       "22_mdd_psychotic": [
//         "No",
//         "There is no mention of the patient exhibiting psychotic symptoms.",
//       ],
//       "23_mdd_catatonia": [
//         "No",
//         "There is no information provided about the patient exhibiting catatonia symptoms.",
//       ],
//       "24_mdd_peripartum": [
//         "No",
//         "There is no mention of the onset of symptoms being related to pregnancy or the postpartum period.",
//       ],
//       "25_mdd_seasonal": [
//         "No",
//         "There is no information provided about the depressive episode occurring in a seasonal pattern.",
//       ],
//     },
//   ],
//   [
//     "summary_sud",
//     {
//       chronic_pain: [],
//       cultural_belief: [
//         "No",
//         "The patient does not mention any cultural, religious, or spiritual beliefs that impact their views on substance use or recovery.",
//       ],
//       motivation: [
//         "No",
//         "The patient does not express any motivation or desire to stop or reduce substance use.",
//       ],
//       readiness: [
//         "No",
//         "The patient does not express readiness to engage in treatment.",
//       ],
//       coping_strategy: [
//         "No",
//         "The patient does not mention any strategies used to cope with stress, cravings, or emotional issues.",
//       ],
//       past_coping_strategy: [
//         "No",
//         "The patient does not mention any previously used healthy or alternative coping strategies.",
//       ],
//       relapse: [
//         "No",
//         "The patient does not explicitly mention any past remission or relapse in their substance use.",
//       ],
//       substance: ["Headache"],
//       secondary_substances: [],
//       substance_category: ["others"],
//       frequency: [],
//       administration: ["Unknown"],
//       substance_start: [],
//       control_usage: [
//         "No",
//         "The patient does not mention using the substance in larger amounts or over a longer period than intended.",
//       ],
//       control_desire: [
//         "No",
//         "The patient does not express a desire or unsuccessful efforts to cut down or control their substance use.",
//       ],
//       control_commitment: [
//         "No",
//         "The patient does not mention spending significant amounts of time engaging in activities to obtain the substance, using the substance, or recovering from its effects.",
//       ],
//       control_arduous: [
//         "No",
//         "The patient does not mention experiencing cravings or a strong desire or urge to use the substance.",
//       ],
//       social_failure: [
//         "No",
//         "The patient does not mention recurrent substance use resulting in a failure to fulfill major role obligations at work, school, or home.",
//       ],
//       social_persistence: [
//         "No",
//         "The patient does not mention continuing to use the substance despite experiencing persistent social or interpersonal problems caused or worsened by its effects.",
//       ],
//       social_decline: [
//         "No",
//         "The patient does not mention withdrawing from or reducing their social activities with family or friends, or decreased participation in occupational, recreational, or hobby activities due to substance use.",
//       ],
//       risk_hazard: [
//         "No",
//         "The patient does not mention using the substance in hazardous situations.",
//       ],
//       risk_tenacity: [
//         "No",
//         "The patient does not mention continuing to use the substance despite being aware of the physical or psychological difficulties and problems it is causing.",
//       ],
//       tolerance_dosage_increase: [
//         "No",
//         "The patient does not mention needing increased amounts of the substance to achieve the desired effect.",
//       ],
//       tolerance_tolerance: [
//         "No",
//         "The patient does not mention experiencing diminished or reduced effects from the substance when using the same amount.",
//       ],
//       withdrawal_relief: [
//         "No",
//         "The patient does not mention a cessation or reduction in substance use, and using the substance to relieve or avoid withdrawal symptoms.",
//       ],
//       withdrawal_symptoms: [
//         "No",
//         "The patient does not mention reducing or stopping substance use, after which they report experiencing withdrawal symptoms.",
//       ],
//       remission_sud: ["No", "The patient does not mention being in remission."],
//       "1_dsm_control_usage": [
//         "No",
//         "The patient does not mention using the substance in larger amounts or over a longer period than intended.",
//       ],
//       "2_dsm_control_desire": [
//         "No",
//         "The patient does not express a desire or unsuccessful efforts to cut down or control their substance use.",
//       ],
//       "3_dsm_control_commitment": [
//         "No",
//         "The patient does not mention spending significant amounts of time engaging in activities to obtain the substance, using the substance, or recovering from its effects.",
//       ],
//       "4_dsm_control_arduous": [
//         "No",
//         "The patient does not mention experiencing cravings or a strong desire or urge to use the substance.",
//       ],
//       "5_dsm_social_failure": [
//         "No",
//         "The patient does not mention recurrent substance use resulting in a failure to fulfill major role obligations at work, school, or home.",
//       ],
//       "6_dsm_social_persistence": [
//         "No",
//         "The patient does not mention continuing to use the substance despite experiencing persistent social or interpersonal problems caused or worsened by its effects.",
//       ],
//       "7_dsm_social_decline": [
//         "No",
//         "The patient does not mention withdrawing from or reducing their social activities with family or friends, or decreased participation in occupational, recreational, or hobby activities due to substance use.",
//       ],
//       "8_dsm_risk_hazard": [
//         "No",
//         "The patient does not mention using the substance in hazardous situations.",
//       ],
//       "9_dsm_risk_tenacity": [
//         "No",
//         "The patient does not mention continuing to use the substance despite being aware of the physical or psychological difficulties and problems it is causing.",
//       ],
//       "10_a_dsm_tolerance_dosage_increase": [
//         "No",
//         "The patient does not mention needing increased amounts of the substance to achieve the desired effect.",
//       ],
//       "10_b_dsm_tolerance_tolerance": [
//         "No",
//         "The patient does not mention experiencing diminished or reduced effects from the substance when using the same amount.",
//       ],
//       "11_a_dsm_withdrawal_relief": [
//         "No",
//         "The patient does not mention a cessation or reduction in substance use, and using the substance to relieve or avoid withdrawal symptoms.",
//       ],
//       "11_b_dsm_withdrawal_symptoms": [
//         "No",
//         "The patient does not mention reducing or stopping substance use, after which they report experiencing withdrawal symptoms.",
//       ],
//       "10_dsm_tolerance": ["No"],
//       "11_dsm_withdrawal": ["No"],
//       "10_c_dsm_tolerance_symptoms": ["No", "No Info provided"],
//     },
//   ],
//   [
//     "summary_ptsd",
//     {
//       "1_ptsd_criterion_a": [
//         "No",
//         "There is no mention of the patient being exposed to a traumatic event.",
//       ],
//       "1_a_ptsd_exposure_direct": [
//         "No",
//         "There is no indication that the patient directly experienced a traumatic event.",
//       ],
//       "1_b_ptsd_exposure_witnessing": [
//         "No",
//         "There is no mention of the patient witnessing a traumatic event happening to others.",
//       ],
//       "1_c_ptsd_exposure_learning": [
//         "No",
//         "The patient does not report learning about a traumatic event involving a close family member or friend.",
//       ],
//       "1_d_ptsd_exposure_aversive_details": [
//         "No",
//         "There is no evidence of the patient being repeatedly exposed to aversive details of a traumatic event, such as in a work-related setting.",
//       ],
//       "": [],
//       "2_ptsd_criterion_b": [
//         "No",
//         "There is no mention of the patient experiencing intrusion symptoms related to a traumatic event.",
//       ],
//       "2_a_ptsd_intrusion_memories": [
//         "No",
//         "The patient does not report experiencing recurrent, involuntary, and intrusive distressing memories.",
//       ],
//       "2_b_ptsd_intrusion_dreams": [
//         "No",
//         "There is no indication of the patient having recurrent distressing dreams related to a traumatic event.",
//       ],
//       "2_c_ptsd_intrusion_flashbacks": [
//         "No",
//         "The patient does not describe experiencing dissociative reactions or flashbacks.",
//       ],
//       "2_d_ptsd_intrusion_distress": [
//         "No",
//         "There is no mention of the patient experiencing intense psychological distress when exposed to cues resembling a traumatic event.",
//       ],
//       "2_e_ptsd_intrusion_reactions": [
//         "No",
//         "The patient does not report experiencing marked physiological reactions when exposed to cues related to a traumatic event.",
//       ],
//       "3_ptsd_criterion_c": [
//         "No",
//         "There is no evidence of the patient avoiding internal or external reminders associated with a traumatic event.",
//       ],
//       "3_a_ptsd_avoidance_thoughts": [
//         "No",
//         "The patient does not mention avoiding distressing thoughts or feelings related to a traumatic event.",
//       ],
//       "3_b_ptsd_avoidance_reminders": [
//         "No",
//         "There is no indication of the patient avoiding external reminders associated with a traumatic event.",
//       ],
//       "4_ptsd_criterion_d": [
//         "No",
//         "There is no mention of the patient exhibiting negative alterations in cognition and mood related to a traumatic event.",
//       ],
//       "4_a_ptsd_memory_loss": [
//         "No",
//         "The patient does not report an inability to remember aspects of a traumatic event.",
//       ],
//       "4_b_ptsd_negative_beliefs": [
//         "No",
//         "There is no evidence of the patient holding persistent, exaggerated negative beliefs or expectations about themselves, others, or the world.",
//       ],
//       "4_c_ptsd_self_blame": [
//         "No",
//         "The patient does not express distorted cognitions leading to self-blame or blaming others for a traumatic event.",
//       ],
//       "4_d_ptsd_emotional_state": [
//         "No",
//         "There is no mention of the patient exhibiting a persistent negative emotional state.",
//       ],
//       "4_e_ptsd_interest_loss": [
//         "No",
//         "The patient does not report a diminished interest in significant activities.",
//       ],
//       "4_f_ptsd_detachment": [
//         "No",
//         "There is no indication of the patient feeling detached or estranged from others.",
//       ],
//       "4_g_ptsd_positive_emotions": [
//         "No",
//         "The patient does not mention an inability to experience positive emotions.",
//       ],
//       "5_ptsd_criterion_e": [
//         "No",
//         "There is no evidence of the patient exhibiting alterations in arousal and reactivity related to a traumatic event.",
//       ],
//       "5_a_ptsd_irritability": [
//         "No",
//         "The patient does not report exhibiting irritable behavior or angry outbursts.",
//       ],
//       "5_b_ptsd_reckless_behavior": [
//         "No",
//         "There is no mention of the patient engaging in reckless or self-destructive behavior.",
//       ],
//       "5_c_ptsd_hypervigilance": [
//         "No",
//         "The patient does not demonstrate hypervigilance.",
//       ],
//       "5_d_ptsd_startle_response": [
//         "No",
//         "There is no indication of the patient having an exaggerated startle response.",
//       ],
//       "5_e_ptsd_concentration": [
//         "No",
//         "The patient does not report problems with concentration.",
//       ],
//       "5_f_ptsd_sleep_disturbance": [
//         "No",
//         "There is no mention of the patient experiencing sleep disturbances.",
//       ],
//       "6_ptsd_duration": [
//         "No",
//         "There is no information provided about the duration of the patient's symptoms.",
//       ],
//       "7_ptsd_impairment": [
//         "No",
//         "There is no evidence of the patient's symptoms causing significant distress or impairment in social, occupational, or other important areas of functioning.",
//       ],
//       "8_ptsd_exclusion": [
//         "No",
//         "There is no information to determine whether the patient's symptoms are attributable to substance use, medication, or another medical condition.",
//       ],
//       "9_a_ptsd_dissociation_depersonalization": [
//         "No",
//         "The patient does not report experiencing depersonalization or feeling detached from themselves.",
//       ],
//       "9_b_ptsd_dissociation_derealization": [
//         "No",
//         "There is no mention of the patient experiencing derealization or feeling detached from their surroundings.",
//       ],
//       "10_ptsd_delayed_expression": [
//         "No",
//         "There is no information provided about the timing of the patient's symptom onset in relation to a traumatic event.",
//       ],
//     },
//   ],
//   [
//     "summary_sleep",
//     {
//       "1_insomnia_criterion_a": [
//         "No",
//         "There is no clear indication in the transcript that the patient reports sleep difficulties.",
//       ],
//       "1_a_insomnia_difficulty_initiating_sleep": [
//         "No",
//         "The transcript does not mention any difficulty initiating sleep.",
//       ],
//       "1_b_insomnia_difficulty_maintaining_sleep": [
//         "No",
//         "There is no information about difficulty maintaining sleep or frequent awakenings.",
//       ],
//       "1_c_insomnia_early_morning_awakening": [
//         "No",
//         "The transcript does not mention early morning awakenings or inability to return to sleep.",
//       ],
//       "2_insomnia_distress_impairment": [
//         "No",
//         "There is no information about sleep disturbance causing distress or impairment.",
//       ],
//       "3_insomnia_frequency": [
//         "No",
//         "The frequency of sleep difficulties is not mentioned in the transcript.",
//       ],
//       "4_insomnia_duration": [
//         "No",
//         "The duration of sleep difficulties is not specified.",
//       ],
//       "5_insomnia_opportunity": [
//         "No",
//         "There is no information about the patient having adequate opportunity for sleep.",
//       ],
//       "6_insomnia_exclusion_other_disorders": [
//         "No",
//         "The transcript does not provide enough information to exclude other sleep-wake disorders.",
//       ],
//       "7_insomnia_exclusion_substances": [
//         "No",
//         "There is no mention of substance use or medication that could explain insomnia symptoms.",
//       ],
//       "8_insomnia_exclusion_medical_mental": [
//         "No",
//         "The transcript does not mention any medical or mental conditions that could explain insomnia.",
//       ],
//       "9_hypersomnolence_criterion_a": [
//         "No",
//         "There is no clear indication of excessive sleepiness or hypersomnolence in the transcript.",
//       ],
//       "9_a_hypersomnolence_recurrent_sleep_periods": [
//         "No",
//         "The transcript does not mention recurrent periods of sleep or lapses into sleep during the day.",
//       ],
//       "9_b_hypersomnolence_prolonged_sleep": [
//         "No",
//         "There is no information about prolonged sleep episodes or sleeping more than 9 hours per day.",
//       ],
//       "9_c_hypersomnolence_difficulty_awakening": [
//         "No",
//         "The transcript does not mention difficulty being fully awake after abrupt awakening.",
//       ],
//       "10_hypersomnolence_frequency": [
//         "No",
//         "The frequency of hypersomnolence is not specified in the transcript.",
//       ],
//       "11_hypersomnolence_distress_impairment": [
//         "No",
//         "There is no information about hypersomnolence causing distress or impairment.",
//       ],
//       "12_hypersomnolence_exclusion_other_disorders": [
//         "No",
//         "The transcript does not provide enough information to exclude other sleep-wake disorders.",
//       ],
//       "13_hypersomnolence_exclusion_substances": [
//         "No",
//         "There is no mention of substance use or medication that could explain hypersomnolence.",
//       ],
//       "14_hypersomnolence_exclusion_medical_mental": [
//         "No",
//         "The transcript does not mention any medical or mental conditions that could explain hypersomnolence.",
//       ],
//       "15_a_hypersomnolence_severity_mild": [
//         "No",
//         "There is no information about the severity of hypersomnolence.",
//       ],
//       "15_b_hypersomnolence_severity_moderate": [
//         "No",
//         "There is no information about the severity of hypersomnolence.",
//       ],
//       "15_c_hypersomnolence_severity_severe": [
//         "No",
//         "There is no information about the severity of hypersomnolence.",
//       ],
//       "16_circadian_disruption": [
//         "No",
//         "The transcript does not mention any disruption or misalignment of the circadian rhythm.",
//       ],
//       "17_circadian_sleepiness_insomnia": [
//         "No",
//         "There is no information about sleepiness or insomnia related to circadian rhythm disruption.",
//       ],
//       "18_circadian_distress_impairment": [
//         "No",
//         "The transcript does not mention any distress or impairment caused by sleep disruption.",
//       ],
//       "19_a_circadian_delayed_sleep_phase": [
//         "No",
//         "There is no information about delayed sleep phase or delayed sleep onset and awakening times.",
//       ],
//       "19_b_circadian_advanced_sleep_phase": [
//         "No",
//         "The transcript does not mention advanced sleep phase or advanced sleep onset and awakening times.",
//       ],
//       "19_c_circadian_irregular_sleep_wake": [
//         "No",
//         "There is no indication of an irregular sleep-wake pattern or temporally disorganized sleep-wake cycle.",
//       ],
//       "19_d_circadian_non_24_hour": [
//         "No",
//         "The transcript does not describe a non-24-hour sleep-wake cycle or daily drifting sleep onset/wake times.",
//       ],
//       "19_e_circadian_shift_work": [
//         "No",
//         "There is no information about shift work or sleep issues related to work schedules.",
//       ],
//       "19_f_circadian_unspecified": [
//         "No",
//         "There is no evidence of any circadian rhythm sleep-wake disorder in the transcript.",
//       ],
//     },
//   ],
// ];
// const staticDignosisAPIResponseFormat = [
//   {
//     Principal: ["GAD", "PTSD", "CRSWD","MMD"],
//     Subthreshold: [ "INSOMNIA"],
//   },
//   [
//     {
//       sud: {
//         score_sud: "N/A",
//         severity_sud: "none",
//         remission: "none",
//         main_substance: "N/A",
//         secondary_substances: "N/A",
//         diagnosis: [],
//         withdrawal: [],
//       },
//       sud_induced: {
//         diagnosis: [],
//       },
//       mdd: {
//         diagnosis: [
//           {
//             disorder: "MMD Anxiety Disorder",
//             severity: "moderate",
//             code: "F41.1",
//           },
//         ],
//       },
//       gad: {
//         diagnosis: [
//           {
//             disorder: "Generalized Anxiety Disorder",
//             severity: "moderate",
//             code: "F41.1",
//           },
//         ],
//       },
//       ptsd: {
//         diagnosis: [
//           {
//             disorder: "PTSD",
//             code: "F43.10",
//           },
//         ],
//       },
//       sleep: {
//         diagnosis: [
//           {
//             disorder: "Circadian Rhythm Sleep-Wake Disorder_Unspecified type",
//             code: "G47.20",
//           },
//         ],
//       },
//     },
//   ],
// ];
const SessionReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { adminPractitionerId, role } = useAuth();
  const { patientDetails, sessionid } = location.state || {};
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const [duration, setDuration] = useState<string>("");
  const sessionTranscriptintervalIdRef = useRef<number | null>(null);
  const sessionSummaryintervalIdRef = useRef<number | null>(null);
  // const sessionAIDADignosisintervalIdRef = useRef<number | null>(null);
  const sessionTreatmentPlanintervalIdRef = useRef<number | null>(null);
  const [sessionDate, setSessionDate] = useState("-");
  const [sesssionTime, setSessionTime] = useState("-");

  // Session Transcript
  const [sessionTranscriptList, setSessionTranscriptList] = useState<any[]>([]);
  const [sessionTranscriptLoading] = useState<boolean>(false);
  const [sessionTranscriptisExpanded, setSessionTranscriptisExpanded] =
    useState(false);

  // Session Summary
  const [sessionSummaryList, setSessionSummaryList] = useState<any[]>([]);
  const [sessionSummaryLoading] = useState<boolean>(false);
  const [sessionSummaryIsExpanded, setsessionSummaryIsExpanded] =
    useState(false);
  const [sessionSummaryListData, setSessionSummaryListData] = useState<
    SummaryEntry[]
  >([]);
  const [sessionSummaryGeneralData, setSessionSummaryGeneralData] =
    useState<any>({});
  const [sessionSummarySudData, setSessionSummarySudData] = useState<any>({});
  const [sessionSummaryType, setSessionSummaryType] = useState("");

  // Diagnosis
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);
  const [diagnosisLoading, setDignosisLoading] = useState<boolean>(false);
  const [diagnosisIsExpanded, setDiagnosisIsExpanded] = useState(false);
  const [principalClinicalFindingsList, setPrincipalClinicalFindingsList] =
    useState<any[]>([]);
  // const [availableDignosisTypeList, setAvailableDignosisTypeList] = useState<
  //   String[]
  // >([]);
  // const [isAllDignosisEmpty, setIsAllDignosisEmpty] = useState(false);
  const [dignosisPrincipalList, setDignosisPrincipalList] = useState<String[]>(
    []
  );
  const [dignosisSubthresholdList, setDignosisSubthresholdList] = useState<
    String[]
  >([]);

  // Treatment plan
  const [treatmentPlanList, setTreatmentPlanList] = useState<any[]>([]);
  const [treatmentLoading] = useState<boolean>(false);
  const [treatmentIsExpanded, setTreatmentPlanIsExpanded] = useState(false);
  const [pharmacologicalMedicationList, setpharmacologicalMedicationList] =
    useState<any[]>([]);
  const [
    nonPharmacologicalMedicationList,
    setNonPharmacologicalMedicationList,
  ] = useState<any[]>([]);

  const [currentExpandedReport, setCurrentExpandedReport] = useState<
    null | number
  >(null);

  const [diagnoDetailsSummary, setDiagnoDetailsSummary] = useState<
    SessionDignosisType[]
  >([]);

  // let [identInfo, setIdentInfo] = useState<any>({
  //   visit: "NA",
  //   education: "NA",
  //   employment: "NA",
  // });
  // let [patientInfo, setPatientInfo] = useState<any>({
  //   complaint: "NA",
  //   firearm: "NA",
  //   legal_problem: "NA",
  //   past_psychiatric_history: "NA",
  //   violence: "NA",
  //   gender_issue: "NA",
  //   child_trauma: "NA",
  //   relationship: "NA",
  //   friends: "NA",
  //   previous_medical_history: "NA",
  //   liver_problem: "NA",
  //   heart_problem: "NA",
  //   vitamin_deficiency: "NA",
  //   infection: "NA",
  //   drug_allergy: "NA",
  //   medicaiton: "NA",
  //   mental_health: "NA",
  //   other_mental_health: "NA",
  //   cognitive_history: "NA",
  //   cognitive_functioning: "NA",
  //   suicide_attempt: "NA",
  //   suicide_ideation: "NA",
  //   safety: "NA",
  // });

  // let [substanceInfo, setSubstanceInfo] = useState<any>({
  //   substance: "NA",
  //   secondary_substances: "NA",
  //   frequency: "NA",
  //   administration: "NA",
  //   substance_start: "NA",
  //   chronic_pain: "NA",
  //   cultural_belief: "NA",
  //   motivation: "NA",
  //   readiness: "NA",
  //   coping_strategy: "NA",
  //   past_coping_strategy: "NA",
  //   withdrawal: "NA",
  //   relapse: "NA",
  //   overdose: "NA",
  // });
  // let [diagnosInfo, setDiagnosInfo] = useState<any>({
  //   framework: "NA",
  //   diagnosis: "NA",
  //   secondary_diagnosis: "NA",
  //   lab_work: "NA",
  // });
  // let [treatmentInfo, setTreatmentInfo] = useState<any>({
  //   treatment: "NA",
  //   medicaiton: "NA",
  //   pregnancy: "NA",
  //   follow_up: "NA",
  // });
  // let [aidaTouchInfo, setAidaTouchInfo] = useState<any>({
  //   vocabulary: "NA",
  // });

  let [diagnoDetailInfo, setDiagnoDetailInfo] = useState<String[]>([]);

  // let [diagnoDetailsServerityList, setDiagnoDetailsServerityList] =
  //   useState<any>([]);

  // let [priDiagnoRefInfo, setPriDiagnoRefInfo] = useState<any>({
  //   "1_dsm_control_usage": "NA",
  //   "2_dsm_control_desire": "NA",
  //   "3_dsm_control_commitment": "NA",
  //   "4_dsm_control_arduous": "NA",
  //   "5_dsm_social_failure": "NA",
  //   "6_dsm_social_persistence": "NA",
  //   "7_dsm_social_decline": "NA",
  //   "8_dsm_risk_hazard": "NA",
  //   "9_dsm_risk_tenacity": "NA",
  //   "10_dsm_tolerance": "NA",
  //   "10_a_dsm_tolerance_dosage_increase": "NA",
  //   "10_b_dsm_tolerance_tolerance": "NA",
  //   "10_c_dsm_tolerance_symptoms": "NA",
  //   "11_dsm_withdrawal": "NA",
  //   "11_a_dsm_withdrawal_relief": "NA",
  //   "11_b_dsm_withdrawal_symptoms": "NA",
  // });

  let [treatmentDetailInfo, setTreatmentDetailInfo] = useState<any>({
    psychotherapy: "NA",
    substance_cessation: "NA",
    supportive_measures: "NA",
    pharmacotherapy: "NA",
    indication: "NA",
    fda_pregnancy_categories: "NA",
    breastfeeding_warnings: "NA",
    contraindications: "NA",
    age_warnings: "NA",
  });

  const handleViewMore = (menu: string) => {
    if (menu === "session_transcript") {
      setSessionTranscriptisExpanded((prev) => !prev);
      setCurrentExpandedReport(1);
    } else if (menu === "session_summary") {
      setsessionSummaryIsExpanded((prev) => !prev);
      setCurrentExpandedReport(2);
    } else if (menu === "diagosis") {
      setDiagnosisIsExpanded((prev) => !prev);
      setCurrentExpandedReport(3);
    } else if (menu === "treatment_plan") {
      setTreatmentPlanIsExpanded((prev) => !prev);
      setCurrentExpandedReport(4);
    }
  };

  const feedbackExpanded = (reportid: number) => {
    if (reportid === 1) {
      setSessionTranscriptisExpanded((prev) => !prev);
    } else if (reportid === 2) {
      setsessionSummaryIsExpanded((prev) => !prev);
    } else if (reportid === 3) {
      setDiagnosisIsExpanded((prev) => !prev);
    } else if (reportid === 4) {
      setTreatmentPlanIsExpanded((prev) => !prev);
    }
  };

  useEffect(() => {
    getSessionDetails();
    fetchSessionTranscript();
    fetchSessionSummary();
    fetchSessionAIDATreatmentPlan();
    // fetchSessionAIDADignosis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sessionTranscriptAttemptCount = 0; // Initialize attempt counter
    let sessionSummaryAttemptCount = 0;
    // let sessionAIDADignosisAttemptCount = 0;
    let sessionTreatmentAttemptCount = 0;

    sessionTranscriptintervalIdRef.current = window.setInterval(async () => {
      sessionTranscriptAttemptCount++;

      const apiResponse = await fetchSessionTranscript();

      if (
        apiResponse ||
        sessionTranscriptAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionTranscriptintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTranscriptintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionSummaryintervalIdRef.current = window.setInterval(async () => {
      sessionSummaryAttemptCount++;

      const apiResponse = await fetchSessionSummary();
      fetchSessionAIDADignosis();

      if (
        apiResponse ||
        sessionSummaryAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionSummaryintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionSummaryintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    // sessionAIDADignosisintervalIdRef.current = window.setInterval(async () => {
    //   sessionAIDADignosisAttemptCount++;

    //   const apiResponse = await fetchSessionAIDADignosis();

    //   if (
    //     apiResponse ||
    //     sessionAIDADignosisAttemptCount >= sessionReportMaxAttemptCount
    //   ) {
    //     clearInterval(sessionAIDADignosisintervalIdRef.current!); // Clear interval after 10 attempts or success
    //     sessionAIDADignosisintervalIdRef.current = null; // Reset intervalRef
    //   }
    // }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionTreatmentPlanintervalIdRef.current = window.setInterval(async () => {
      sessionTreatmentAttemptCount++;

      const apiResponse = await fetchSessionAIDATreatmentPlan();

      if (
        apiResponse ||
        sessionTreatmentAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTreatmentPlanintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    // Cleanup on unmount
    return () => {
      if (sessionTranscriptintervalIdRef.current !== null) {
        clearInterval(sessionTranscriptintervalIdRef.current);
      }

      if (sessionSummaryintervalIdRef.current !== null) {
        clearInterval(sessionSummaryintervalIdRef.current);
      }

      // if (sessionAIDADignosisintervalIdRef.current !== null) {
      //   clearInterval(sessionAIDADignosisintervalIdRef.current);
      // }

      if (sessionTreatmentPlanintervalIdRef.current !== null) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSessionTranscript = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        setDignosisLoading(true);
        const APIResponse = await getData(
          `/transcriptions/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      } else {
        setDignosisLoading(true);
        const APIResponse = await getData(
          `/transcriptions/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };
  // Function to process the API response
  const processSessionTranscriptAPIResponse = async (APIResponse: any) => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const mergedAudioSegments = APIResponse.flatMap(
        (item: { results: { audio_segments: any } }) =>
          item.results.audio_segments
      );
      if (mergedAudioSegments.length) {
        setSessionTranscriptList(mergedAudioSegments);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      const { download_url } = APIResponse;

      const transcriptResponse = await fetch(download_url);
      const transcriptData = await transcriptResponse.json();
      setSessionTranscriptList(transcriptData);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const getSessionDetails = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/session/${adminPractitionerId}/${sessionid}`
        );
        if (APIResponse) {
          const result = splitDateTime(APIResponse.audio_created_at);
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      } else {
        const APIResponse = await getData(
          `/session/${practionerDetails.practionerInfo.id}/${sessionid}`
        );
        if (APIResponse) {
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          const result = splitDateTime(APIResponse.audio_created_at);
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
    }
  };

  const fetchSessionSummary = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/summaries/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/summaries/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
        // return processSessionSummaryAPIResponse(staticSummaryAPIResponseFormat);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionSummaryAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setSessionSummaryList(sessionSummary);
        setSessionSummaryData(sessionSummary);
        confirmSessionSummaryType(APIResponse);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionSummaryList([APIResponse]);
      setSessionSummaryData([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const confirmSessionSummaryType = async (summaryData: SummaryEntry[]) => {
    try {
      // Find the "summary_general" entry
      const summaryGeneralEntry = summaryData.find(
        ([key]) => key === "summary_general"
      );
      // Extract the object if the entry is found
      const summaryGeneralValue = summaryGeneralEntry
        ? summaryGeneralEntry[1]
        : null;
      const summaryType =
        summaryGeneralValue?.patient_category?.[0] ?? "Mental";
      setSessionSummaryType(summaryType);

      setSessionSummaryGeneralData(summaryGeneralValue);

      // Find the "summary_sud" entry
      const summarySudEntry = summaryData.find(
        ([key]) => key === "summary_sud"
      );
      // Extract the object if the entry is found
      const summarySudValue = summarySudEntry ? summarySudEntry[1] : null;
      setSessionSummarySudData(summarySudValue);
      setSessionSummaryListData(summaryData);
      fetchSessionAIDADignosis();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSessionAIDADignosis = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        setDignosisLoading(true);
        const APIResponse = await getData(
          `/diagnosis/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      } else {
        setDignosisLoading(true);
        const APIResponse = await getData(
          `/diagnosis/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
        // return processSessionAIDADignosisAPIResponse(
        //   staticDignosisAPIResponseFormat
        // );
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDADignosisAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionDignosis = transformData(APIResponse);
      if (sessionDignosis.length) {
        let principalClinicalFindingsList: any = [];
        // eslint-disable-next-line array-callback-return
        Object.entries(sessionDignosis[2]).map(
          // eslint-disable-next-line array-callback-return
          ([key, value]: [string, any]) => {
            principalClinicalFindingsList = [value];
          }
        );
        setPrincipalClinicalFindingsList(principalClinicalFindingsList);
        setDiagnosisList(sessionDignosis);
        prepareDiagnosisData(sessionDignosis, principalClinicalFindingsList);
        return true; // API response is not empty
      } else {
        return false; // API response is not empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setDiagnosisList([APIResponse]);
      prepareDiagnosisData([APIResponse], principalClinicalFindingsList);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDATreatmentPlan = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/treatment/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/treatment/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDATreatmenPlantAPIResponse = (
    APIResponse: any
  ): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const isNoTreatment = checkNoTreatement(APIResponse);
      const treatmentPlan = transformData(APIResponse);
      if (isNoTreatment) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlan);
        return true; // No Need call Chunking API
      }
      if (treatmentPlan.length) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlan);
        return true; // API response is not empty
      } else {
        return false;
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setTreatmentPlanList([APIResponse]);
      setTreatmentPlanData(treatmentPlanList);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const transformData = (input: DataItem[]): DataItem[] => {
    return input.reduce((acc: DataItem[], obj: DataItem) => {
      Object.keys(obj).forEach((key: string) => {
        acc.push({ [key]: obj[key] });
      });
      return acc;
    }, []);
  };

  const setSessionSummaryData = (sessionSummary: any) => {
    let identiObj: any = {};
    let patientObj: any = {};
    let substanceObj: any = {};
    let diagnosObj: any = {};
    let treatmentObj: any = {};
    let aidaTouchObj: any = {};
    let priDiagnoObj: any = {};
    // let obj = {};
    sessionSummary.map((sessionData: any) => {
      Object.entries(sessionData).map(([key, value]) => {
        if (key === "visit") {
          identiObj["visit"] = value;
        }
        if (key === "education") {
          identiObj["education"] = value;
        }
        if (key === "employment") {
          identiObj["employment"] = value;
        }
        if (key === "complaint") {
          patientObj["complaint"] = value;
        }
        if (key === "firearm") {
          patientObj["firearm"] = value;
        }
        if (key === "legal_problem") {
          patientObj["legal_problem"] = value;
        }
        if (key === "past_psychiatric_history") {
          patientObj["past_psychiatric_history"] = value;
        }
        if (key === "violence") {
          patientObj["violence"] = value;
        }
        if (key === "gender_issue") {
          patientObj["gender_issue"] = value;
        }
        if (key === "child_trauma") {
          patientObj["child_trauma"] = value;
        }
        if (key === "relationship") {
          patientObj["relationship"] = value;
        }
        if (key === "friends") {
          patientObj["friends"] = value;
        }
        if (key === "previous_medical_history") {
          patientObj["previous_medical_history"] = value;
        }
        if (key === "liver_problem") {
          patientObj["liver_problem"] = value;
        }
        if (key === "heart_problem") {
          patientObj["heart_problem"] = value;
        }
        if (key === "vitamin_deficiency") {
          patientObj["vitamin_deficiency"] = value;
        }
        if (key === "infection") {
          patientObj["infection"] = value;
        }
        if (key === "drug_allergy") {
          patientObj["drug_allergy"] = value;
        }
        if (key === "medicaiton") {
          patientObj["medicaiton"] = value;
        }
        if (key === "mental_health") {
          patientObj["mental_health"] = value;
        }
        if (key === "other_mental_health") {
          patientObj["other_mental_health"] = value;
        }
        if (key === "cognitive_history") {
          patientObj["cognitive_history"] = value;
        }
        if (key === "cognitive_functioning") {
          patientObj["cognitive_functioning"] = value;
        }
        if (key === "suicide_attempt") {
          patientObj["suicide_attempt"] = value;
        }
        if (key === "suicide_ideation") {
          patientObj["suicide_ideation"] = value;
        }
        if (key === "safety") {
          patientObj["safety"] = value;
        }

        if (key === "substance") {
          substanceObj["substance"] = value;
        }
        if (key === "secondary_substances") {
          substanceObj["secondary_substances"] = value;
        }
        if (key === "frequency") {
          substanceObj["frequency"] = value;
        }
        if (key === "administration") {
          substanceObj["administration"] = value;
        }
        if (key === "substance_start") {
          substanceObj["substance_start"] = value;
        }
        if (key === "chronic_pain") {
          substanceObj["chronic_pain"] = value;
        }
        if (key === "cultural_belief") {
          substanceObj["cultural_belief"] = value;
        }
        if (key === "motivation") {
          substanceObj["motivation"] = value;
        }
        if (key === "readiness") {
          substanceObj["readiness"] = value;
        }
        if (key === "coping_strategy") {
          substanceObj["coping_strategy"] = value;
        }
        if (key === "past_coping_strategy") {
          substanceObj["past_coping_strategy"] = value;
        }
        if (key === "withdrawal") {
          substanceObj["withdrawal"] = value;
        }
        if (key === "relapse") {
          substanceObj["relapse"] = value;
        }
        if (key === "overdose") {
          substanceObj["overdose"] = value;
        }

        if (key === "framework") {
          diagnosObj["framework"] = value;
        }
        if (key === "diagnosis") {
          diagnosObj["diagnosis"] = value;
        }
        if (key === "secondary_diagnosis") {
          diagnosObj["secondary_diagnosis"] = value;
        }
        if (key === "lab_work") {
          diagnosObj["lab_work"] = value;
        }

        if (key === "treatment") {
          treatmentObj["treatment"] = value;
        }
        if (key === "medicaiton") {
          treatmentObj["medicaiton"] = value;
        }
        if (key === "pregnancy") {
          treatmentObj["pregnancy"] = value;
        }
        if (key === "follow_up") {
          treatmentObj["follow_up"] = value;
        }

        if (key === "vocabulary") {
          aidaTouchObj["vocabulary"] = value;
        }

        if (key === "1_dsm_control_usage") {
          priDiagnoObj["1_dsm_control_usage"] = value;
        }
        if (key === "2_dsm_control_desire") {
          priDiagnoObj["2_dsm_control_desire"] = value;
        }
        if (key === "3_dsm_control_commitment") {
          priDiagnoObj["3_dsm_control_commitment"] = value;
        }
        if (key === "4_dsm_control_arduous") {
          priDiagnoObj["4_dsm_control_arduous"] = value;
        }
        if (key === "5_dsm_social_failure") {
          priDiagnoObj["5_dsm_social_failure"] = value;
        }
        if (key === "6_dsm_social_persistence") {
          priDiagnoObj["6_dsm_social_persistence"] = value;
        }
        if (key === "7_dsm_social_decline") {
          priDiagnoObj["7_dsm_social_decline"] = value;
        }
        if (key === "8_dsm_risk_hazard") {
          priDiagnoObj["8_dsm_risk_hazard"] = value;
        }
        if (key === "9_dsm_risk_tenacity") {
          priDiagnoObj["9_dsm_risk_tenacity"] = value;
        }
        if (key === "10_dsm_tolerance") {
          priDiagnoObj["10_dsm_tolerance"] = value;
        }
        if (key === "10_a_dsm_tolerance_dosage_increase") {
          priDiagnoObj["10_a_dsm_tolerance_dosage_increase"] = value;
        }
        if (key === "10_b_dsm_tolerance_tolerance") {
          priDiagnoObj["10_b_dsm_tolerance_tolerance"] = value;
        }
        if (key === "10_c_dsm_tolerance_symptoms") {
          priDiagnoObj["10_c_dsm_tolerance_symptoms"] = value;
        }
        if (key === "11_dsm_withdrawal") {
          priDiagnoObj["11_dsm_withdrawal"] = value;
        }
        if (key === "11_a_dsm_withdrawal_relief") {
          priDiagnoObj["11_a_dsm_withdrawal_relief"] = value;
        }
        if (key === "11_b_dsm_withdrawal_symptoms") {
          priDiagnoObj["11_b_dsm_withdrawal_symptoms"] = value;
        }
        return sessionData;
      });
      return sessionData;
    });
    // identInfo = identiObj;
    // patientInfo = patientObj;
    // substanceInfo = substanceObj;
    // treatmentInfo = treatmentObj;
    // diagnosInfo = diagnosObj;
    // aidaTouchInfo = aidaTouchObj;
    // priDiagnoRefInfo = priDiagnoObj;
    // setIdentInfo(identiObj);
    // setPatientInfo(patientObj);
    // setSubstanceInfo(substanceObj);
    // setDiagnosInfo(diagnosObj);
    // setTreatmentInfo(treatmentObj);
    // setAidaTouchInfo(aidaTouchObj);
    // setPriDiagnoRefInfo(priDiagnoObj);
  };

  const prepareDiagnosisData = (
    diagnosisData: any,
    principalClinicalFindingsList: any
  ) => {
    const sortOrder = ["mdd", "gad", "sud", "sud-induced"];
    let primaryDignosisarray: any = [];
    let availableDignosisTypeListArray: String[] = [];
    principalClinicalFindingsList.map((diagnosisData: any) => {
      const keysWithMultipleChildren: { parentKey: string; value: any }[] = [];
      // eslint-disable-next-line array-callback-return
      Object.entries(diagnosisData).map(([key, value]: [string, any]) => {
        if (typeof value === "object" && value !== null) {
          const diagnosisArray = value?.diagnosis || [];
          if (diagnosisArray.length) {
            availableDignosisTypeListArray.push(key);
            let disorder: any = [];
            let code: any = [];
            let severity: any = [];
            // eslint-disable-next-line array-callback-return
            diagnosisArray.map((diagno: any) => {
              if (diagno.disorder || diagno.code || diagno.severity) {
                disorder = [...disorder, diagno.disorder];
                code = [...code, diagno.code];
                severity = [...severity, diagno.severity];
                diagno.type = key;
                primaryDignosisarray.push(diagno);
                return diagno;
              }
            });
          }
        }
      });
      return keysWithMultipleChildren;
    });
    const dignosisCodeList = primaryDignosisarray
      .sort(
        (a: { type: string }, b: { type: string }) =>
          sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
      )
      .map((item: { code: any }) => item.code);

    primaryDignosisarray.sort((a: { type: string }, b: { type: string }) => {
      return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
    });

    setDiagnoDetailsSummary(primaryDignosisarray);
    setDiagnoDetailInfo(dignosisCodeList);

    // Find "Principal" values
    const principalValues = diagnosisData
      .map((item: { Principal: any }) => item.Principal) // Get "Principal" key values
      .filter(Boolean) // Remove undefined values
      .flat(); // Flatten nested arrays
    setDignosisPrincipalList(principalValues);

    // Find "Subthreshold" values
    const subthresholdValues = diagnosisData
      .map((item: { Subthreshold: any }) => item.Subthreshold) // Get "Principal" key values
      .filter(Boolean) // Remove undefined values
      .flat(); // Flatten nested arrays
    setDignosisSubthresholdList(subthresholdValues);
    setDignosisLoading(false);
  };

  // const isAnyDiagnosisNotEmpty = (diagnosisData: any) => {
  //   return diagnosisData.some(
  //     (entry: ArrayLike<unknown> | { [s: string]: unknown }) =>
  //       Object.values(entry).some(
  //         (section: any) =>
  //           Array.isArray(section.diagnosis) && section.diagnosis.length > 0
  //       )
  //   );
  // };

  const setTreatmentPlanData = (treatmentPlan: any) => {
    let treatmentDetailObj: any = {};
    let psychotherapy: any = [];
    let pharmacotherapy: any = [];
    let substance_cessation: any = [];
    let indication: any = [];
    let fda_pregnancy_categories: any = [];
    let supportive_measures: any = [];
    let breastfeeding_warnings: any = [];
    let contraindications: any = [];
    let age_warnings: any = [];
    let pharmacologicalMedicationData: any[] = [];
    let nonPharmacologicalMedicationData: any[] = [];
    Object.values(treatmentPlan).map((obj: any) => {
      Object.entries(obj).map(([key, value]: any) => {
        const tmpObj: any = {};
        tmpObj.code = key;
        if (value.psychotherapy !== "N/A") {
          tmpObj.psychotherapy = value.psychotherapy;
          psychotherapy = [...psychotherapy, value.psychotherapy];
        }
        if (value.substance_cessation !== "N/A") {
          tmpObj.substance_cessation = value.substance_cessation;
          substance_cessation = [
            ...substance_cessation,
            value.substance_cessation,
          ];
        }
        if (value.supportive_measures !== "N/A") {
          tmpObj.supportive_measures = value.supportive_measures;
          supportive_measures = [
            ...supportive_measures,
            value.supportive_measures,
          ];
        }
        if (
          value?.medication &&
          value?.medication &&
          value?.medication.length > 0
        ) {
          value?.medication.map((medica: any) => {
            medica.code = key;
            pharmacologicalMedicationData.push(medica);
            pharmacotherapy = [...pharmacotherapy, medica.pharmacotherapy];
            indication = [...indication, medica.indication];
            fda_pregnancy_categories = [
              ...fda_pregnancy_categories,
              medica.fda_pregnancy_categories,
            ];
            breastfeeding_warnings = [
              ...breastfeeding_warnings,
              medica.breastfeeding_warnings,
            ];
            contraindications = [
              ...contraindications,
              medica.contraindications,
            ];
            age_warnings = [...age_warnings, medica.age_warnings];
            return medica;
          });
          nonPharmacologicalMedicationData.push(tmpObj);
        }
        return value;
      });
      return obj;
    });
    treatmentDetailObj.psychotherapy = psychotherapy;
    treatmentDetailObj.substance_cessation = substance_cessation;
    treatmentDetailObj.supportive_measures = supportive_measures;
    treatmentDetailObj.supportive_measures = psychotherapy;
    treatmentDetailObj.pharmacotherapy = pharmacotherapy;
    treatmentDetailObj.indication = indication;
    treatmentDetailObj.fda_pregnancy_categories = fda_pregnancy_categories;
    treatmentDetailObj.breastfeeding_warnings = breastfeeding_warnings;
    treatmentDetailObj.contraindications = contraindications;
    treatmentDetailObj.age_warnings = age_warnings;
    treatmentDetailInfo = treatmentDetailObj;
    setpharmacologicalMedicationList(pharmacologicalMedicationData);
    setNonPharmacologicalMedicationList(nonPharmacologicalMedicationData);
    setTreatmentDetailInfo(treatmentDetailObj);
  };

  const checkNoTreatement = (response: any) => {
    const expectedResponse = [
      {
        Treatment:
          "There is currently no treatment plan available for this session.",
      },
    ];
    const isSameResponse =
      JSON.stringify(response) === JSON.stringify(expectedResponse);

    return isSameResponse;
  };

  const downloadTxtFile = (menu: string) => {
    // Step 1: Create the content for the file
    // Create text content dynamically using map
    let content = "";
    let fileName = "sample.txt";
    if (menu === "session_transcript") {
      content = sessionTranscriptList
        .map((item, i) => `${item.speaker}: ${item.text}`)
        .join("\n"); // Join each transcript item with a new line

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} SessionTranscript.txt`;
    } else if (menu === "session_treatment_plan") {
      content =
        document.getElementById("sessionTreatmentPlanDiv")?.innerText ||
        "There is No Session Treatement found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDATreatmentPlan.txt`;
    } else if (menu === "session_summary") {
      content =
        document.getElementById("sessionSummaryDiv")?.innerText ||
        "There is No Session Summary found.";
      fileName = `${patientDetails.first_name}-${patientDetails.last_name} Session-Notes.txt`;
    } else if (menu === "aida_dignosis") {
      content =
        document.getElementById("sessionDignosisDiv")?.innerText ||
        "There is No Session Dignosis found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDA-Diagnosis.txt`;
    }

    // Step 2: Create a Blob object with the content as plain text
    const blob = new Blob([content], { type: "text/plain" });

    // Step 3: Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Step 4: Create an anchor element and simulate a click
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName; // Name of the file with .txt extension
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl); // Revoke the object URL after download
  };

  const handleBack = () => {
    if (sessionSummaryIsExpanded) {
      setsessionSummaryIsExpanded(false);
    } else if (diagnosisIsExpanded) {
      setDiagnosisIsExpanded(false);
    } else if (treatmentIsExpanded) {
      setTreatmentPlanIsExpanded(false);
    } else {
      setSessionTranscriptisExpanded(false);
    }
    if (
      !sessionSummaryIsExpanded &&
      !diagnosisIsExpanded &&
      !treatmentIsExpanded &&
      !sessionTranscriptisExpanded
    ) {
      navigate("/patient-details", {
        state: { patientDetails: patientDetails },
      });
    }
  };

  return (
    <AppDrawer>
      <Paper
        variant="outlined"
        component="fieldset"
        sx={{
          backgroundColor: "#ffcc00",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 3, fontSize: "medium" }}
            >
              {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
              {patientDetails?.last_name ? patientDetails.last_name : "-"}
              <Divider
                sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
              ></Divider>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#ffcc00",
                borderRadius: "05px",
                border: "1px solid black",
                color: "black",
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Box height={10}></Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ fontWeight: "bold", fontSize: "small" }}
        >
          <Grid item xs={3}>
            Initial Consultation Date
          </Grid>
          <Grid item xs={3}>
            Appointment Time
          </Grid>

          <Grid item xs={2}>
            Appointment Date
          </Grid>
          <Grid item xs={2}>
            Duration
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sesssionTime}
          </Grid>

          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {duration} Min
          </Grid>
        </Grid>
      </Paper>
      <Box height={10}></Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        AIDA Reports
      </Typography>
      <Box height={10}></Box>

      <Grid container spacing={2}>
        {/* Session Summary */}
        {!sessionTranscriptisExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionSummaryIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionSummaryIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <Note sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Summary
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionSummary}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionSummaryDiv"
                    sx={{
                      flex: 1,
                      minHeight: "150px",
                      maxHeight: sessionSummaryIsExpanded ? "300px" : "50px",
                      overflowY: sessionSummaryIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease",
                      overflowX: "hidden",
                    }}
                  >
                    {sessionSummaryListData &&
                      sessionSummaryType?.trim() !== "" &&
                      (sessionSummaryType === "Non_Mental" ? (
                        <>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            SOAP Note Summary
                          </span>
                          <ul>
                            <li style={sessionReportSubtitle}>Subjective:</li>
                            <SubFormatArray
                              subIndex={"sex"}
                              keyName="Sex"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"chief_complaint"}
                              keyName="Chief Complaint (CC)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness (HPI)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"change_since_last_vist"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history"}
                              keyName="History"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"psychiatric_history"}
                              keyName="Psychiatric History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Cognitive History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"childhood_trauma"}
                              keyName="Childhood Trauma"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"social_history"}
                              keyName="Social History"
                              value={" "}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"home_and_environment"}
                              keyName={"Home and Environment"}
                              values={[
                                sessionSummaryGeneralData?.["relationship"],
                                sessionSummaryGeneralData?.["friends"],
                                sessionSummaryGeneralData?.["support_system"],
                                sessionSummaryGeneralData?.["stress_level"],
                                sessionSummaryGeneralData?.["trigger"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"education"}
                              keyName={"Education"}
                              values={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"employment"}
                              keyName={"Employment"}
                              values={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"eating_and_sleeping"}
                              keyName={"Eating and Sleep"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ],
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                  [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"activities"}
                              keyName={"Activities"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ],
                                sessionSummaryGeneralData?.["energy_level"],
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"drugs"}
                              keyName={"Drugs"}
                              values={[
                                sessionSummarySudData?.["substance"],
                                sessionSummarySudData?.["secondary_substances"],
                                sessionSummarySudData?.["substance_start"],
                                sessionSummarySudData?.["frequency"],
                                sessionSummarySudData?.["administration"],
                                sessionSummaryGeneralData?.["overdose"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"suicide"}
                              keyName={"Suicide"}
                              values={[
                                sessionSummaryGeneralData?.["suicide_attempt"],
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || [],
                              ]}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_surgical_history"}
                              keyName="Medical/Surgical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_family_history"}
                              keyName="Medical Family History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "family_history_medical"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"review_of_system"}
                              keyName="Review of Systems (ROS)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"current_medications"}
                              keyName="Current Medications"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <li style={sessionReportSubtitle}>Objective:</li>
                            <SubFormatArray
                              subIndex={"vital_signs"}
                              keyName="Vital Signs, Physical Exam, and Mental Status Examination"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"psychiatric"}
                              keyName="Psychiatric, Psychological, or Cognitive Tests"
                              value={
                                sessionSummaryGeneralData?.["framework"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"laboratory_tests"}
                              keyName="Laboratory Tests"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"medical_problems"}
                              keyName={"Medical Problems"}
                              value={[
                                sessionSummaryGeneralData?.["liver_problem"],
                                sessionSummaryGeneralData?.["heart_problem"],
                                sessionSummaryGeneralData?.["thyroid_problem"],
                                sessionSummaryGeneralData?.["adrenal_problem"],
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalances"
                                ],
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || [],
                              ]}
                            />
                            <li style={sessionReportSubtitle}>Assesment:</li>
                            <SubFormatArray
                              subIndex={"primary_diagnosis"}
                              keyName="Primary Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diffential_diagnosis"}
                              keyName="Differential Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["assessment"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"risk_factor"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"safety_and_legal"}
                              keyName="Safety and Legal Concerns"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"access_to_firearms"}
                              keyName="Access to Firearms"
                              value={
                                sessionSummaryGeneralData?.["firearm"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"unsafe_environment"}
                              keyName="Unsafe Living Environment"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"history_of_violence"}
                              keyName="History of Violence"
                              value={
                                sessionSummaryGeneralData?.["violence"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"legal_issues"}
                              keyName="Legal Issues"
                              value={
                                sessionSummaryGeneralData?.["legal_problem"] ||
                                []
                              }
                            />
                            <li style={sessionReportSubtitle}>Plan:</li>
                            <SubFormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <SubFormatArray
                              subIndex={"laboratory"}
                              keyName="Laboratory"
                              value={
                                sessionSummaryGeneralData?.["lab_work"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"special_considerations"}
                              keyName="Special Considerations"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"insurance"}
                              keyName="Insurance"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"pregnancy"}
                              keyName="Pregnancy"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            Detailed Summary
                          </span>
                          <br />
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            General Information
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"purpose_of_visit"}
                              keyName="Purpose of Visit"
                              value={
                                sessionSummaryGeneralData?.["visit_scenario"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Consultation Medium"
                              value={
                                sessionSummaryGeneralData?.["medium"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Demographic Details:
                            </li>
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"gender"}
                              keyName="Gender"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />

                            <SubFormatArray
                              subIndex={"education_level"}
                              keyName="Education Level"
                              value={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"employment_status"}
                              keyName="Employment Status"
                              value={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Presenting Complaints
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chiefcomplaint"}
                              keyName="Chief Complaint"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"changes_since_last_visit"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                          </ul>
                          {/* Common End** */}
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Medical and Physical Health History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"past_surgeries_and_hospitalizations"}
                              keyName="Past Surgeries and Hospitalizations"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"family_medical_history"}
                              keyName="Family Medical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "family_medical_history"
                                ] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Medical Conditions:
                            </li>
                            <SubFormatArray
                              subIndex={"Liver Issues"}
                              keyName="Liver Issues"
                              value={
                                sessionSummaryGeneralData?.["liver_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="heart_issues"
                              value={
                                sessionSummaryGeneralData?.["heart_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"thyroid_problems"}
                              keyName="Thyroid Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "thyroid_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"adrenal_problems"}
                              keyName="Adrenal Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "adrenal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"neurological_conditions"}
                              keyName="Neurological Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"autoimmune_inflammatory_disorders"}
                              keyName="Autoimmune/Inflammatory Disorders"
                              value={
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"infections"}
                              keyName="Infections"
                              value={
                                sessionSummaryGeneralData?.["infection"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="hormonal_imbalances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalnces"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gastrointestinal_issues"}
                              keyName="Gastrointestinal Issues"
                              value={
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diabetes_mellitus"}
                              keyName="Diabetes Mellitus"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"vitamin_deficiency"}
                              keyName="Vitamin Deficiency"
                              value={
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"immunization_status"}
                              keyName="Immunization Status"
                              value={
                                sessionSummaryGeneralData?.[
                                  "immunization_status"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"medication"}
                              keyName="Medication"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Mental Health and Psychiatric History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"EmotionalWellBeing"}
                              keyName="Current Mental Health Status"
                              value={
                                sessionSummaryGeneralData?.["mental_health"]
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Cognitive Functioning"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_functioning"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Gender Identity and Related Issues"
                              value={
                                sessionSummaryGeneralData?.["gender_issue"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"previous_psychiatric_problems"}
                              keyName="Previous Psychiatric Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"childhood_trauma_history"}
                              keyName="Childhood Trauma History"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Substance Use History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"primary_substance"}
                              keyName="Primary Substance"
                              value={
                                sessionSummaryGeneralData?.["substance"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"secondary_substances"}
                              keyName="Secondary Substances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_substances"
                                ] || []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Usage Patterns:
                            </li>
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Substance Use Onset"
                              value={
                                sessionSummaryGeneralData?.[
                                  "substance_start"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Frequency and Dosage"
                              value={
                                sessionSummaryGeneralData?.["frequency"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Method of Administration"
                              value={
                                sessionSummaryGeneralData?.["administration"] ||
                                []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Family and Social History
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Lifestyle Factors:
                            </li>
                            <SubFormatArray
                              subIndex={"friends_and_colleagues"}
                              keyName="Physical Activity"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diet_and_nutrition"}
                              keyName="Diet and Nutrition"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"sleep_patterns"}
                              keyName="Sleep Patterns"
                              value={
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Social History:
                            </li>
                            <SubFormatArray
                              subIndex={"relationship_status"}
                              keyName="Relationship Status"
                              value={
                                sessionSummaryGeneralData?.["relationship"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Friends"
                              value={
                                sessionSummaryGeneralData?.["friends"] || []
                              }
                            />

                            <SubFormatArray
                              subIndex={"past_coping_mechanisms"}
                              keyName="Support System"
                              value={
                                sessionSummaryGeneralData?.["support_system"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Home and Environment Safety"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Clinical Examination
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"physical_examination"}
                              keyName="Physical Examination"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"review_of_Systems_(ROS)"}
                              keyName="Review of Systems (ROS)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"growth_and_development"}
                              keyName="Growth and Development (for Pediatrics Visits)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "growth_and_development"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Diagnostic Findings
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"physical_examination"}
                              keyName="Health Maintenance (Preventive Care Review)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "health_maintenance_review"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"review_of_Systems_(ROS)"}
                              keyName="Lab Results"
                              value={
                                sessionSummaryGeneralData?.[
                                  "recent_lab_work"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Clinical Evaluation
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={
                                "problem_list_and_differential_diagnosis"
                              }
                              keyName="Problem List and Differential Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["assessment"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"risk_factor_assessment"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"functional_status"}
                              keyName="Functional Status (Impact on Daily Life)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "functional_status"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Treatment and Follow-Up
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"diagnosis"}
                              keyName="Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Special Considerations:
                            </li>
                            <SubFormatArray
                              subIndex={"pregnancy_status"}
                              keyName="Pregnancy Status"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"comorbid_conditions"}
                              keyName="Comorbid Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_diagnosis"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"insurance_coverage"}
                              keyName="Insurance Coverage"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                          </ul>
                        </>
                      ) : (
                        <>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            SOAP Note Summary
                          </span>
                          <ul>
                            <li style={sessionReportSubtitle}>
                              Subjective (Patient-Reported Information):
                            </li>
                            <SubFormatArray
                              subIndex={"sex"}
                              keyName="Sex"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"Reason for Visit (Chief Complaint)"}
                              keyName="Reason for Visit (Chief Complaint)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"History of Current Issue"}
                              keyName="History of Current Issue"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />

                            <SubFormatArray
                              subIndex={
                                "Mental Health, Psychiatric, and Cognitive History"
                              }
                              keyName={
                                "Mental Health, Psychiatric, and Cognitive History"
                              }
                              value={[
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ],
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_medication"
                                ],
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ],
                                sessionSummaryGeneralData?.[
                                  "cognitive_functioning"
                                ],
                                sessionSummaryGeneralData?.["mental_health"],
                                sessionSummaryGeneralData?.[
                                  "other_mental_health"
                                ] || [],
                              ]}
                            />

                            <SubFormatArray
                              subIndex={"Family and Childhood History"}
                              keyName={"Family and Childhood History"}
                              value={[
                                sessionSummaryGeneralData?.["child_trauma"],
                                sessionSummaryGeneralData?.["cultural_belief"],
                                sessionSummaryGeneralData?.[
                                  "family_history_medical"
                                ] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"Education and Employment Status"}
                              keyName={"Education and Employment Status"}
                              value={[
                                sessionSummaryGeneralData?.["education"],
                                sessionSummaryGeneralData?.["employment"] || [],
                              ]}
                            />

                            <SubFormatArray
                              subIndex={
                                "Relationship, Social, and Environmental Factors"
                              }
                              keyName={
                                "Relationship, Social, and Environmental Factors"
                              }
                              value={[
                                sessionSummaryGeneralData?.["relationship"],
                                sessionSummaryGeneralData?.["friends"],
                                sessionSummaryGeneralData?.["support_system"],
                                sessionSummaryGeneralData?.["stress_level"],
                                sessionSummaryGeneralData?.["trigger"] || [],
                              ]}
                            />

                            <SubFormatArray
                              subIndex={"Lifestyle and Daily Functioning"}
                              keyName={"Lifestyle and Daily Functioning"}
                              value={[
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ],
                                sessionSummaryGeneralData?.["sleep_paBern"],
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ],
                                sessionSummaryGeneralData?.["daily_routine"],
                                sessionSummaryGeneralData?.["energy_level"],
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ],
                                sessionSummaryGeneralData?.["self_care"] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"drugs"}
                              keyName={"Drugs"}
                              value={[
                                sessionSummarySudData?.["substance"],
                                sessionSummarySudData?.["secondary_substances"],
                                sessionSummarySudData?.["substance_start"],
                                sessionSummarySudData?.["frequency"],
                                sessionSummarySudData?.["administration"],
                                sessionSummaryGeneralData?.["overdose"] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"suicide"}
                              keyName={"Suicide"}
                              value={[
                                sessionSummaryGeneralData?.["suicide_attempt"],
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"medical_surgical_history"}
                              keyName="Medical/Surgical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"current_medications"}
                              keyName="Current Medications"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <li style={sessionReportSubtitle}>
                              Objective (Clinical ObservaMons & Test Results):
                            </li>
                            <SubFormatArray
                              subIndex={"Examinations and Tests"}
                              keyName="Examinations and Tests"
                              value={[
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ],
                                sessionSummaryGeneralData?.["framework"],
                                sessionSummaryGeneralData?.["recent_lab_work"],
                                sessionSummaryGeneralData?.[
                                  "mental_status_examination"
                                ],
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"medical_problems"}
                              keyName={"Medical Problems"}
                              value={[
                                sessionSummaryGeneralData?.["liver_problem"],
                                sessionSummaryGeneralData?.["heart_problem"],
                                sessionSummaryGeneralData?.["thyroid_problem"],
                                sessionSummaryGeneralData?.["adrenal_problem"],
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalances"
                                ],
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ],
                                sessionSummaryGeneralData?.["infection"],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || [],
                              ]}
                            />
                            <li style={sessionReportSubtitle}>Assesment:</li>
                            <SubFormatArray
                              subIndex={"Diagnostic Impression"}
                              keyName={"Diagnostic Impression"}
                              value={[
                                sessionSummaryGeneralData?.["diagnosis"],
                                sessionSummaryGeneralData?.[
                                  "secondary_diagnosis"
                                ] || [],
                              ]}
                            />
                            <SubFormatArray
                              subIndex={"risk_factor"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <li style={sessionReportSubtitle}>Plan:</li>
                            <SubFormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <SubFormatArray
                              subIndex={"special_considerations"}
                              keyName="Special Considerations"
                              value={[
                                sessionSummaryGeneralData?.["allergies"],
                                sessionSummaryGeneralData?.["pregnancy"],
                                sessionSummaryGeneralData?.["other"],
                                sessionSummaryGeneralData?.["insurance"],
                                sessionSummaryGeneralData?.["firearm"],
                                sessionSummaryGeneralData?.["safety"],
                                sessionSummaryGeneralData?.["violence"],
                                sessionSummaryGeneralData?.["legal_problem"],
                                sessionSummaryGeneralData?.["reaction"] || [],
                              ]}
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            Detailed Summary
                          </span>
                          <br />
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            General Information
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"purpose_of_visit"}
                              keyName="Purpose of Visit"
                              value={
                                sessionSummaryGeneralData?.["visit_scenario"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Consultation Medium"
                              value={
                                sessionSummaryGeneralData?.["medium"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Demographic Details:
                            </li>
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"gender"}
                              keyName="Gender"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />

                            <SubFormatArray
                              subIndex={"education_level"}
                              keyName="Education Level"
                              value={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"employment_status"}
                              keyName="Employment Status"
                              value={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Presenting Complaints
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chiefcomplaint"}
                              keyName="Chief Complaint"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"changes_since_last_visit"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                          </ul>
                          {/* Common End** */}
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Mental Health and Psychiatric History
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Current Mental Health Status
                            </li>
                            <SubFormatArray
                              subIndex={"EmotionalWellBeing"}
                              keyName="Emotional Well-Being"
                              value={
                                sessionSummaryGeneralData?.["mental_health"]
                              }
                            />
                            <SubFormatArray
                              subIndex={"consultation_medium"}
                              keyName="Cognitive Functioning"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_functioning"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"consultation_medium"}
                              keyName="Coping Strategies"
                              value={
                                sessionSummarySudData?.["coping_strategy"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Past Psychiatric History
                            </li>
                            <SubFormatArray
                              subIndex={"previous_psychiatric_problems"}
                              keyName="Previous Psychiatric Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Cognitive History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ] || []
                              }
                            />

                            <SubFormatArray
                              subIndex={"past_coping_mechanisms"}
                              keyName="Past Coping Mechanisms"
                              value={
                                sessionSummarySudData?.[
                                  "past_coping_strategy"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Gender Identity and Related Issues"
                              value={
                                sessionSummaryGeneralData?.["gender_issue"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Suicide Risk Assessment
                            </li>
                            <SubFormatArray
                              subIndex={"suicide_attempts"}
                              keyName="Suicide Attempts"
                              value={
                                sessionSummaryGeneralData?.[
                                  "suicide_attempt"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"suicide_attempts"}
                              keyName="Suicide Ideation or Plans"
                              value={
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Psychiatric Medication Review"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_medication"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Substance Use History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"primary_substance"}
                              keyName="Primary Substance"
                              value={sessionSummarySudData?.["substance"] || []}
                            />
                            <FormatArray
                              subIndex={"secondary_substances"}
                              keyName="Secondary Substances"
                              value={
                                sessionSummarySudData?.[
                                  "secondary_substances"
                                ] || []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Usage Patterns:
                            </li>
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Substance Use Onset"
                              value={
                                sessionSummarySudData?.["substance_start"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Frequency and Dosage"
                              value={sessionSummarySudData?.["frequency"] || []}
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Method of Administration"
                              value={
                                sessionSummarySudData?.["administration"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Substance-Related Risks
                            </li>
                            <SubFormatArray
                              subIndex={"history_of_r_elapse"}
                              keyName="History of Relapse"
                              value={sessionSummarySudData?.["relapse"] || []}
                            />
                            <SubFormatArray
                              subIndex={"history_of_overdose"}
                              keyName="History of Overdose"
                              value={sessionSummarySudData?.["overdose"] || []}
                            />
                            <SubFormatArray
                              subIndex={"withdrawal_symptoms"}
                              keyName="Withdrawal Symptoms"
                              value={
                                sessionSummarySudData?.[
                                  "withdrawal_symptoms"
                                ] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Motivation and Readiness for Change:
                            </li>
                            <SubFormatArray
                              subIndex={"motivation_to_quit"}
                              keyName="Motivation to Quit"
                              value={
                                sessionSummarySudData?.["motivation"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"readiness_to_engage_in_treatment"}
                              keyName="Readiness to Engage in Treatment"
                              value={sessionSummarySudData?.["readiness"] || []}
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Lifestyle and Daily Functioning
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"sleep_patterns"}
                              keyName="Sleep Patterns"
                              value={
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"Physical_activity"}
                              keyName="Physical Activity"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"energy_Levels"}
                              keyName="Energy Levels"
                              value={
                                sessionSummaryGeneralData?.["energy_level"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"daily_routine"}
                              keyName="Daily Routine"
                              value={
                                sessionSummaryGeneralData?.["daily_routine"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"diet_and_nutrition"}
                              keyName="Diet and Nutrition"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"stress_level"}
                              keyName="Stress Level"
                              value={
                                sessionSummaryGeneralData?.["stress_level"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={
                                "triggers_for_mental_health_episodes_or_substance_use"
                              }
                              keyName="Triggers for Mental Health Episodes or Substance Use"
                              value={
                                sessionSummaryGeneralData?.["trigger"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"hobbies_and_interests"}
                              keyName="Hobbies and Interests"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"self_perception_and_confidence"}
                              keyName="Self-Perception and Confidence"
                              value={
                                sessionSummaryGeneralData?.[
                                  "self_perception_and_confidence"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"selfcare_habits"}
                              keyName="Self-Care Habits"
                              value={
                                sessionSummaryGeneralData?.["self_care"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Family and Social History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"history_of_childhood_trauma"}
                              keyName="History of Childhood Trauma"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"family_relationships"}
                              keyName="Family Relationships"
                              value={
                                sessionSummaryGeneralData?.["relationship"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Social Relationships:
                            </li>
                            <SubFormatArray
                              subIndex={"friends_and_colleagues"}
                              keyName="Friends and Colleagues"
                              value={
                                sessionSummaryGeneralData?.["friends"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="Support Systems"
                              value={
                                sessionSummaryGeneralData?.["support_system"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"cultural_and_religious_beliefs"}
                              keyName="Cultural and Religious Beliefs"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cultural_belief"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Safety and Legal Concerns
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Safety Risks:
                            </li>
                            <SubFormatArray
                              subIndex={"access_to_firearms"}
                              keyName="Access To Firearms"
                              value={
                                sessionSummaryGeneralData?.["firearm"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"unsafe_living_environment"}
                              keyName="Unsafe Living Environment"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history_of_violence"}
                              keyName="History of Violence"
                              value={
                                sessionSummaryGeneralData?.["violence"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"legal_issues_(ongoing or past)"}
                              keyName="Legal Issues (ongoing or past)"
                              value={
                                sessionSummaryGeneralData?.["legal_problem"] ||
                                []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Medical and Physical Health History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chronic_pain_history"}
                              keyName="Chronic Pain History"
                              value={
                                sessionSummaryGeneralData?.["chronic_pain"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Medical Conditions:
                            </li>
                            <SubFormatArray
                              subIndex={"Liver Issues"}
                              keyName="Liver Issues"
                              value={
                                sessionSummaryGeneralData?.["liver_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="heart_issues"
                              value={
                                sessionSummaryGeneralData?.["heart_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"thyroid_problems"}
                              keyName="Thyroid Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "thyroid_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"adrenal_problems"}
                              keyName="Adrenal Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "adrenal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"neurological_conditions"}
                              keyName="Neurological Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"autoimmune_inflammatory_disorders"}
                              keyName="Autoimmune/Inflammatory Disorders"
                              value={
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"infections"}
                              keyName="Infections"
                              value={
                                sessionSummaryGeneralData?.["infection"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="hormonal_imbalances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalnces"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gastrointestinal_issues"}
                              keyName="Gastrointestinal Issues"
                              value={
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diabetes_mellitus"}
                              keyName="Diabetes Mellitus"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"vitamin_deficiency"}
                              keyName="Vitamin Deficiency"
                              value={
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"past_surgeries_and_hospitalizations"}
                              keyName="Past Surgeries and Hospitalizations"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"medication_review"}
                              keyName="Medication Review"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"recent_lab_results"}
                              keyName="Recent Lab Results"
                              value={
                                sessionSummaryGeneralData?.[
                                  "recent_lab_work"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Screening
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"framework"}
                              keyName="Psychiatric, Psychological, or Cognitive Tests"
                              value={
                                sessionSummaryGeneralData?.["framework"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Treatment and Follow-Up
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"Diagnosis"}
                              keyName="Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Special Considerations:
                            </li>
                            <SubFormatArray
                              subIndex={"pregnancy_status"}
                              keyName="Pregnancy Status"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"comorbid_conditions"}
                              keyName="Comorbid Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_diagnosis"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"insurance_coverage"}
                              keyName="Insurance Coverage"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            AIDA’s Touch
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"language_and_Word_choice"}
                              keyName="Language and Word Choice"
                              value={
                                sessionSummaryGeneralData?.["vocabulary"] || []
                              }
                            />
                          </ul>
                        </>
                      ))}

                    {sessionSummaryListData &&
                      sessionSummaryType?.trim() === "" && (
                        <Typography
                          style={{ textAlign: "center", fontSize: "small" }}
                        >
                          Your reports are being generated. They will
                          automatically appear on screen once ready.
                        </Typography>
                      )}

                    {sessionSummaryLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!sessionSummaryIsExpanded &&
                  !sessionSummaryLoading &&
                  sessionSummaryList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_summary")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionSummaryIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_summary")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionSummaryIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Diagnosis Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={diagnosisIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: diagnosisIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",
                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <StickyNote2 sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Assessment Recommendation Details & Insights
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionAIDADignosis}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionDignosisDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: diagnosisIsExpanded ? "300px" : "150px", // Expand/Collapse max height
                      overflowY: diagnosisIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {sessionSummaryListData.length > 0 &&
                      sessionSummaryType === "Mental" &&
                      diagnosisList.length > 0 &&
                      (dignosisPrincipalList.length > 0 ||
                        dignosisSubthresholdList.length > 0) && (
                        <>
                          <span style={sessionReportTitle}>
                            Assessment Recommendation Summary
                          </span>
                          <ul>
                            <li key="Principal Clinical Findings">
                              <strong>Principal Clinical Findings:</strong>{" "}
                              {diagnoDetailsSummary.length > 0
                                ? diagnoDetailsSummary.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item?.disorder?.length ? (
                                        <>
                                          <br></br>- {item.disorder}
                                        </>
                                      ) : (
                                        " "
                                      )}
                                    </React.Fragment>
                                  ))
                                : " "}
                            </li>
                            <li key="ICD-10 CM code">
                              <strong>ICD-10 CM code:</strong> <br></br>
                              {diagnoDetailInfo.join(" ,")}
                              {/* {diagnoDetailInfo.split(",")} */}
                              {/* {diagnoDetailInfo.length > 0
                                ? diagnoDetailInfo.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <br></br>
                                      {item}
                                    </React.Fragment>
                                  ))
                                : " "} */}
                            </li>
                            {/* <FormatArray
                              subIndex={"code"}
                              keyName="ICD-10 CM code"
                              value={diagnoDetailInfo}
                            /> */}
                          </ul>

                          <span
                            style={{
                              ...sessionReportTitle,
                              display: dignosisPrincipalList.length
                                ? "block"
                                : "none",
                            }}
                          >
                            Assessment Recommendation Details
                          </span>
                          {/* MDD */}
                          {dignosisPrincipalList.includes("MDD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Major Depressive Disorder
                              </h3>
                              <ul>
                                <li key="Principal Clinical Findings">
                                  <strong>Principal Clinical Findings:</strong>{" "}
                                  {principalClinicalFindingsList.length &&
                                  principalClinicalFindingsList.find(
                                    (item) => item.mdd
                                  )?.mdd?.diagnosis
                                    ? principalClinicalFindingsList
                                        .find((item) => item.mdd)
                                        ?.mdd?.diagnosis.map(
                                          (
                                            item: {
                                              disorder: string | any[];
                                              severity: string | any[];
                                            },
                                            index: React.Key | null | undefined
                                          ) => (
                                            <React.Fragment key={index}>
                                              {index === 0 ? (
                                                item?.disorder?.length ? (
                                                  removeLastCommaAndWord(
                                                    item.disorder
                                                  )
                                                ) : (
                                                  "N-A"
                                                )
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "block",
                                                    paddingLeft: "2em",
                                                  }}
                                                >
                                                  {item?.disorder?.length
                                                    ? removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    : " "}
                                                </span>
                                              )}
                                            </React.Fragment>
                                          )
                                        )
                                    : " "}
                                </li>
                                <FormatArray
                                  subIndex={"code"}
                                  keyName="ICD-10 CM code"
                                  value={(() => {
                                    const mddData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.mdd
                                      )?.mdd?.diagnosis || [];
                                    if (mddData.length > 0) {
                                      return mddData.map(
                                        (
                                          item: { code: string | any[] },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.code?.length ? (
                                                `${item.code}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.code?.length
                                                  ? `${item.code}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />

                                <FormatArray
                                  subIndex={"episode_type"}
                                  keyName="Episode Type"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["15_mdd_episode_type"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"serverity"}
                                  keyName="Severity"
                                  value={(() => {
                                    const mudData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.mdd
                                      )?.mdd?.diagnosis || [];
                                    if (mudData.length > 0) {
                                      return mudData.map(
                                        (
                                          item: {
                                            severity: string | any[];
                                          },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.severity?.length ? (
                                                `${item.severity}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.severity?.length
                                                  ? `${item.severity}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />
                                <FormatArray
                                  subIndex={"serverity_partial"}
                                  keyName="Remission Status Partial"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["17a_mdd_remission_partial"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"serverity_full"}
                                  keyName="Remission Status Full"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["17b_mdd_remission_full"] || []
                                  }
                                />

                                <li style={{ fontWeight: "bold" }}>
                                  Specific Features:
                                </li>
                                <SubFormatArray
                                  subIndex={"znxious_distress"}
                                  keyName="Anxious Distress"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["18_mdd_distress"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"mixed_features"}
                                  keyName="Mixed Features"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["19_mdd_mixed"] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"melancholic_features"}
                                  keyName="Melancholic Features"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["20_mdd_melancholic"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"atypical_features"}
                                  keyName="Atypical Features"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["21_mdd_atypical"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"psychotic_features"}
                                  keyName="Psychotic Features"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["22_mdd_psychotic"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"catatonia"}
                                  keyName="Catatonia"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["23_mdd_catatonia"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"peripartum_onset"}
                                  keyName="Peripartum Onset"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["24_mdd_peripartum"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"seasonal_pattern"}
                                  keyName="Seasonal Pattern"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["25_mdd_seasonal"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"diagnostic_frameworks"}
                                  keyName="Diagnostic Frameworks"
                                  value={"ICD-10 & DSM-5 Depressive disorders"}
                                />
                              </ul>
                            </>
                          )}

                          {/* GAD */}
                          {dignosisPrincipalList.includes("GAD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Generalized Anxiety Disorder
                              </h3>
                              <ul>
                                <li key="Principal Clinical Findings">
                                  <strong>Principal Clinical Findings:</strong>{" "}
                                  {principalClinicalFindingsList.length &&
                                  principalClinicalFindingsList.find(
                                    (item) => item.gad
                                  )?.gad?.diagnosis
                                    ? principalClinicalFindingsList
                                        .find((item) => item.gad)
                                        ?.gad?.diagnosis.map(
                                          (
                                            item: {
                                              disorder: string | any[];
                                              severity: string | any[];
                                            },
                                            index: React.Key | null | undefined
                                          ) => (
                                            <React.Fragment key={index}>
                                              {index === 0 ? (
                                                item?.disorder?.length ? (
                                                  removeLastCommaAndWord(
                                                    item.disorder
                                                  )
                                                ) : (
                                                  "N-A"
                                                )
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "block",
                                                    paddingLeft: "2em",
                                                  }}
                                                >
                                                  {item?.disorder?.length
                                                    ? removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    : " "}
                                                </span>
                                              )}
                                            </React.Fragment>
                                          )
                                        )
                                    : " "}
                                </li>
                                <FormatArray
                                  subIndex={"code"}
                                  keyName="ICD-10 CM code"
                                  value={(() => {
                                    const gadData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.gad
                                      )?.gad?.diagnosis || [];
                                    if (gadData.length > 0) {
                                      return gadData.map(
                                        (
                                          item: { code: string | any[] },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.code?.length ? (
                                                `${item.code}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.code?.length
                                                  ? `${item.code}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />

                                <FormatArray
                                  subIndex={"severity"}
                                  keyName="Severity"
                                  value={(() => {
                                    const gadData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.gad
                                      )?.gad?.diagnosis || [];
                                    if (gadData.length > 0) {
                                      return gadData.map(
                                        (
                                          item: {
                                            severity: string | any[];
                                          },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.severity?.length ? (
                                                `${item.severity}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.severity?.length
                                                  ? `${item.severity}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />

                                <FormatArray
                                  subIndex={"diagnostic_frameworks"}
                                  keyName="Diagnostic Frameworks"
                                  value={"ICD-10 & DSM-5 Anxiety disorders"}
                                />
                              </ul>
                            </>
                          )}

                          {/* SUD */}
                          {dignosisPrincipalList.includes("SUD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Substance Use Disorder
                              </h3>
                              <ul>
                                <li key="Principal Clinical Findings">
                                  <strong>Principal Clinical Findings:</strong>{" "}
                                  {principalClinicalFindingsList.find(
                                    (item) => item.sud
                                  )?.sud?.diagnosis
                                    ? principalClinicalFindingsList
                                        .find((item) => item.sud)
                                        ?.sud?.diagnosis.map(
                                          (
                                            item: {
                                              disorder: string | any[];
                                              severity: string | any[];
                                            },
                                            index: React.Key | null | undefined
                                          ) => (
                                            <React.Fragment key={index}>
                                              {index === 0 ? (
                                                item?.disorder?.length ? (
                                                  removeLastCommaAndWord(
                                                    item.disorder
                                                  )
                                                ) : (
                                                  " "
                                                )
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "block",
                                                    paddingLeft: "2em",
                                                  }}
                                                >
                                                  {item?.disorder?.length
                                                    ? removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    : " "}
                                                </span>
                                              )}
                                            </React.Fragment>
                                          )
                                        )
                                    : " "}
                                </li>
                                <FormatArray
                                  subIndex={"code"}
                                  keyName="ICD-10 CM code"
                                  value={(() => {
                                    const sudData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.sud
                                      )?.sud?.diagnosis || [];
                                    if (sudData.length > 0) {
                                      return sudData.map(
                                        (
                                          item: { code: string | any[] },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.code?.length ? (
                                                `${item.code}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.code?.length
                                                  ? `${item.code}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />
                                <FormatArray
                                  subIndex={"substance"}
                                  keyName="Main Substance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["substance"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"secondary_diagnosis"}
                                  keyName="Other Substances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["secondary_substances"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"severity"}
                                  keyName="Severity"
                                  value={(() => {
                                    const sudData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.sud
                                      )?.sud?.diagnosis || [];
                                    if (sudData.length > 0) {
                                      return sudData.map(
                                        (
                                          item: {
                                            severity: string | any[];
                                          },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.severity?.length ? (
                                                `${item.severity}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.severity?.length
                                                  ? `${item.severity}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />
                                <FormatArray
                                  subIndex={"substance"}
                                  keyName="Frameworks"
                                  value="ICD-10 & DSM-5 substance use disorder."
                                />
                              </ul>
                            </>
                          )}

                          {/* PTSD */}
                          {dignosisPrincipalList.includes("PTSD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Post-Traumatic Stress Disorder (PTSD)
                              </h3>
                              <ul>
                                <li key="Principal Clinical Findings">
                                  <strong>Principal Clinical Findings:</strong>{" "}
                                  {principalClinicalFindingsList.find(
                                    (item) => item.ptsd
                                  )?.ptsd?.diagnosis
                                    ? principalClinicalFindingsList
                                        .find((item) => item.ptsd)
                                        ?.ptsd?.diagnosis.map(
                                          (
                                            item: {
                                              disorder: string | any[];
                                              severity: string | any[];
                                            },
                                            index: React.Key | null | undefined
                                          ) => (
                                            <React.Fragment key={index}>
                                              {index === 0 ? (
                                                item?.disorder?.length ? (
                                                  removeLastCommaAndWord(
                                                    item.disorder
                                                  )
                                                ) : (
                                                  " "
                                                )
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "block",
                                                    paddingLeft: "2em",
                                                  }}
                                                >
                                                  {item?.disorder?.length
                                                    ? removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    : " "}
                                                </span>
                                              )}
                                            </React.Fragment>
                                          )
                                        )
                                    : " "}
                                </li>
                                <FormatArray
                                  subIndex={"code"}
                                  keyName="ICD-10 CM code"
                                  value={(() => {
                                    const ptsdData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.ptsd
                                      )?.ptsd?.diagnosis || [];
                                    if (ptsdData.length > 0) {
                                      return ptsdData.map(
                                        (
                                          item: { code: string | any[] },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.code?.length ? (
                                                `${item.code}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.code?.length
                                                  ? `${item.code}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />
                                <FormatArray
                                  subIndex={"Subtype(s)"}
                                  keyName="Subtype(s)"
                                  value=""
                                />
                                <SubFormatArray
                                  subIndex={"Depersonalization"}
                                  keyName="Depersonalization"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.[
                                      "9_a_ptsd_dissociation_depersonalization"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Derealization"}
                                  keyName="Derealization"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.[
                                      "9_b_ptsd_dissociation_derealization"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Delayed Expression"}
                                  keyName="Delayed Expression"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["10_ptsd_delayed_expression"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"substance"}
                                  keyName="Frameworks"
                                  value="ICD-10 & DSM-5 substance use disorder."
                                />
                              </ul>
                            </>
                          )}

                          {/* Sleep Disorder */}
                          {(dignosisPrincipalList.includes("INSOMNIA") ||
                            dignosisPrincipalList.includes("HYPERSOMNOLENCE") ||
                            dignosisPrincipalList.includes("CRSWD")) && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Sleep Disorder
                              </h3>
                              <ul>
                                <li key="Principal Clinical Findings">
                                  <strong>Principal Clinical Findings:</strong>{" "}
                                  {principalClinicalFindingsList.find(
                                    (item) => item.sleep
                                  )?.sleep?.diagnosis
                                    ? principalClinicalFindingsList
                                        .find((item) => item.sleep)
                                        ?.sleep?.diagnosis.map(
                                          (
                                            item: {
                                              disorder: string | any[];
                                              severity: string | any[];
                                            },
                                            index: React.Key | null | undefined
                                          ) => (
                                            <React.Fragment key={index}>
                                              {index === 0 ? (
                                                item?.disorder?.length ? (
                                                  removeLastCommaAndWord(
                                                    item.disorder
                                                  )
                                                ) : (
                                                  " "
                                                )
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "block",
                                                    paddingLeft: "2em",
                                                  }}
                                                >
                                                  {item?.disorder?.length
                                                    ? removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    : " "}
                                                </span>
                                              )}
                                            </React.Fragment>
                                          )
                                        )
                                    : " "}
                                </li>
                                <FormatArray
                                  subIndex={"code"}
                                  keyName="ICD-10 CM code"
                                  value={(() => {
                                    const sleepData =
                                      principalClinicalFindingsList.find(
                                        (item) => item.sleep
                                      )?.sleep?.diagnosis || [];
                                    if (sleepData.length > 0) {
                                      return sleepData.map(
                                        (
                                          item: { code: string | any[] },
                                          index: React.Key | null | undefined
                                        ) => (
                                          <React.Fragment key={index}>
                                            {index === 0 ? (
                                              item.code?.length ? (
                                                `${item.code}`
                                              ) : (
                                                " "
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "block",
                                                  paddingLeft: "2em",
                                                }}
                                              >
                                                {item.code?.length
                                                  ? `${item.code}`
                                                  : " "}
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      );
                                    }
                                    return " ";
                                  })()}
                                />
                                <FormatArray
                                  subIndex={"Frameworks"}
                                  keyName="Frameworks"
                                  value="DSM-5"
                                />
                              </ul>
                            </>
                          )}

                          <span
                            style={{
                              ...sessionReportTitle,
                              display: dignosisPrincipalList.length
                                ? "block"
                                : "none",
                            }}
                          >
                            References for Clinical Findings
                          </span>

                          <span
                            style={{
                              ...dignosisReportSubtitle,
                              display: dignosisPrincipalList.length
                                ? "block"
                                : "none",
                              backgroundColor: "#faf7dc",
                              marginTop: "10px",
                            }}
                          >
                            References for Principal Clinical Findings
                          </span>
                          {/* MDD */}
                          {dignosisPrincipalList.includes("MDD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Major Depressive Disorder
                              </h3>
                              <ul>
                                <li style={{ fontWeight: "bold" }}>
                                  Symptoms:
                                </li>
                                <SubFormatArray
                                  subIndex={"depressed_mood"}
                                  keyName="Depressed Mood"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["1_mdd_mood"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"loss_of_interest_or_pleasure"}
                                  keyName="Loss of Interest or Pleasure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["2_mdd_interest"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "significant_weight_change_or_appetite_alteration"
                                  }
                                  keyName="Significant Weight Change or Appetite Alteration"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["3_mdd_weight"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"sleep_disturbances"}
                                  keyName="Sleep Disturbances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["4_mdd_insomnia"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "psychomotor_agitation_or_retardation"
                                  }
                                  keyName="Psychomotor Agitation or Retardation"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["5_mdd_retardation"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"fatigue_or_loss_of_energy"}
                                  keyName="Fatigue or Loss of Energy"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["6_mdd_fatigue"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "feelings_of_worthlessness_or_excessive_guilt"
                                  }
                                  keyName="Feelings of Worthlessness or Excessive Guilt"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["7_mdd_guilt"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "Diminished_ability_to_think _or_concentrate"
                                  }
                                  keyName="Diminished Ability to Think or Concentrate"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["8_mdd_concentration"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "Recurrent Thoughts of Death or Suicide"
                                  }
                                  keyName="Recurrent Thoughts of Death or Suicide"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["9_mdd_suicide"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"persistency_of_symptoms"}
                                  keyName="Persistency of Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["10_mdd_symptom_count"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"functional_impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["11_mdd_impairment"] || []
                                  }
                                />

                                <li style={{ fontWeight: "bold" }}>
                                  Exclusions:
                                </li>
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Substance Use, Medication, or Medical Condition"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["12_mdd_exclusion_medical"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "schizophrenia_spectrum_or_other_psychotic_disorders"
                                  }
                                  keyName="Schizophrenia Spectrum or Other Psychotic Disorders"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["13_mdd_exclusion_schizo"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "history_of_hypomanic_or_manic_Episodes"
                                  }
                                  keyName="History of Hypomanic or Manic Episodes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["14_mdd_hypomanic"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* GAD */}
                          {dignosisPrincipalList.includes("GAD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Generalized Anxiety Disorder
                              </h3>
                              <ul>
                                <li style={{ fontWeight: "bold" }}>
                                  Symptoms:
                                </li>
                                <SubFormatArray
                                  subIndex={"excessive_anxiety_orworry"}
                                  keyName="Excessive Anxiety or Worry"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["1_gad_symptom"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "difficulty_in_controlling_theworry"
                                  }
                                  keyName="Difficulty in Controlling the Worry"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["3_gad_control_of_worry"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Physical_or_cognitive_symptoms"}
                                  keyName="Physical or Cognitive Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["2_gad_physical_symptoms"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"functional_impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["4_gad_impairment"] || []
                                  }
                                />

                                <li style={{ fontWeight: "bold" }}>
                                  Exclusions:
                                </li>
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Panic Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5a_gad_panic_exclusion"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"social_anxiety_disorder"}
                                  keyName="Social Anxiety Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.[
                                      "5b_gad_social_anxiety_exclusion"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"obsessive_compulsive_disorder"}
                                  keyName="Obsessive-Compulsive Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5c_gad_ocd_exclusion"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Substance Use, Medication, or Medical Condition"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5d_gad_medical_exclusion"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* SUD */}
                          {dignosisPrincipalList.includes("SUD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Substance Use Disorder
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"1_dsm_control_usage"}
                                  keyName="Impaired Control - Usage"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["1_dsm_control_usage"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"2_dsm_control_desire"}
                                  keyName="Impaired Control - Desire To Cut Down"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["2_dsm_control_desire"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"3_dsm_control_commitment"}
                                  keyName="Impaired Control - Time Commitment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["3_dsm_control_commitment"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"4_dsm_control_arduous"}
                                  keyName="Impaired Control - Craving"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["4_dsm_control_arduous"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"5_dsm_social_failure"}
                                  keyName="Social Impairment - Failure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["5_dsm_social_failure"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"6_dsm_social_persistence"}
                                  keyName="Social Impairment - Persistence"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["6_dsm_social_persistence"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"7_dsm_social_decline"}
                                  keyName="Social Impairment - Decline"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["7_dsm_social_decline"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"8_dsm_risk_hazard"}
                                  keyName="Risky Use - Hazard"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["8_dsm_risk_hazard"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"9_dsm_risk_tenacity"}
                                  keyName="Risky Use - Tenacity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["9_dsm_risk_tenacity"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"10_dsm_tolerance"}
                                  keyName="Tolerance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["10_dsm_tolerance"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={
                                    "10_a_dsm_tolerance_dosage_increase"
                                  }
                                  keyName="Tolerance - Dosage Increase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.[
                                      "10_a_dsm_tolerance_dosage_increase"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_dsm_withdrawal"}
                                  keyName="Withdrawal"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_dsm_withdrawal"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_a_dsm_withdrawal_relief"}
                                  keyName="Withdrawal - Relief"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_a_dsm_withdrawal_relief"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_b_dsm_withdrawal_symptoms"}
                                  keyName="Withdrawal - Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_b_dsm_withdrawal_symptoms"] ||
                                    []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* INSOMNIA */}
                          {dignosisPrincipalList.includes("INSOMNIA") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>Insomnia</h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Sleep Difficulties"}
                                  keyName="Sleep Difficulties"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["1_insomnia_criterion_a"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Initiating Sleep"}
                                  keyName="Initiating Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_a_insomnia_difficulty_initiating_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Maintaining Sleep"}
                                  keyName="Maintaining Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_b_insomnia_difficulty_maintaining_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Early Awakening"}
                                  keyName="Early Awakening"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_c_insomnia_early_morning_awakening"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "2_insomnia_distress_impairment"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Frequency"}
                                  keyName="Frequency"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["3_insomnia_frequency"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Duration"}
                                  keyName="Duration"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["4_insomnia_duration"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Adequate Opportunity"}
                                  keyName="Adequate Opportunity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["5_insomnia_opportunity"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Exclusions"}
                                  keyName="Exclusions"
                                  value={" "}
                                />
                                <SubFormatArray
                                  subIndex={"Other Sleep-Wake Disorder"}
                                  keyName="Other Sleep-Wake Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "6_insomnia_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Substances"}
                                  keyName="Substances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "7_insomnia_exclusion_substances"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Medical/Mental"}
                                  keyName="Medical/Mental"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "8_insomnia_exclusion_medical_mental"
                                    ] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* HYPERSOMNOLENCE */}
                          {dignosisPrincipalList.includes(
                            "HYPERSOMNOLENCE"
                          ) && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Hypersomnolence
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Excessive Sleepiness"}
                                  keyName="Excessive Sleepiness"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["9_hypersomnolence_criterion_a"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Recurrent Sleep Episodes"}
                                  keyName="Recurrent Sleep Episodes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_a_hypersomnolence_recurrent_sleep_periods"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Prolonged Sleep"}
                                  keyName="Prolonged Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_b_hypersomnolence_prolonged_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Awakening Difficulty"}
                                  keyName="Awakening Difficulty"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_c_hypersomnolence_difficulty_awakening"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Frequency"}
                                  keyName="Frequency"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["10_hypersomnolence_frequency"] ||
                                    []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "11_hypersomnolence_distress_impairment"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Exclusions:"}
                                  keyName="Exclusions"
                                  value={" "}
                                />
                                <SubFormatArray
                                  subIndex={"Other Sleep-Awake Disorders"}
                                  keyName="Other Sleep-Awake Disorders"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "12_hypersomnolence_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Substances"}
                                  keyName="Substances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "13_hypersomnolence_exclusion_substances"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Medical/Mental"}
                                  keyName="Medical/Mental"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "14_hypersomnolence_exclusion_medical_mental"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Severity"}
                                  keyName="Severity"
                                  value={""}
                                />
                                <SubFormatArray
                                  subIndex={"Mild"}
                                  keyName="Mild"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_a_hypersomnolence_severity_mild"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Moderate"}
                                  keyName="Moderate"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_b_hypersomnolence_severity_moderate"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Severe"}
                                  keyName="Severe"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_c_hypersomnolence_severity_severe"
                                    ] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* Circadian Rhythm Sleep-Wake Disorder */}
                          {dignosisPrincipalList.includes("CRSWD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Circadian Rhythm Sleep-Wake Disorder{" "}
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Circadian Disruption"}
                                  keyName="Circadian Disruption"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["16_circadian_disruption"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Sleepiness/Insomnia"}
                                  keyName="Sleepiness/Insomnia"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "17_circadian_sleepiness_insomnia"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "18_circadian_distress_impairment"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={
                                    "Exclusion of other medical or mental conditions"
                                  }
                                  keyName="Exclusion of other medical or mental conditions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "19_circadian_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Subtype"}
                                  keyName="Subtype"
                                  value=""
                                />
                                <SubFormatArray
                                  subIndex={"Delayed Sleep Phase"}
                                  keyName="Delayed Sleep Phase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_a_circadian_delayed_sleep_phase"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Advanced Sleep Phase"}
                                  keyName="Advanced Sleep Phase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_b_circadian_advanced_sleep_phase"
                                    ] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Irregular Sleep-Wake"}
                                  keyName="Irregular Sleep-Wake"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_c_circadian_irregular_sleep_wake"
                                    ] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Non-24-Hour Sleep-Wake"}
                                  keyName="Non-24-Hour Sleep-Wake"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_d_circadian_non_24_hour"] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Shift Work "}
                                  keyName="Shift Work"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_e_circadian_shift_work"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Unspecified"}
                                  keyName="Unspecified"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_f_circadian_unspecified"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* PTSD */}
                          {dignosisPrincipalList.includes("PTSD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Post-Traumatic Stress Disorder (PTSD){" "}
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Exposure to Traumatic Event(s)"}
                                  keyName="Exposure to Traumatic Event(s)"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_ptsd_criterion_a"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Direct Exposure"}
                                  keyName="Direct Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_a_ptsd_exposure_direct"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Witnessed Exposure"}
                                  keyName="Witnessed Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_b_ptsd_exposure_witnessing"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"delayed_xxpression"}
                                  keyName="Learned Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_c_ptsd_exposure_learning"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Aversive Details Exposure"}
                                  keyName="Aversive Details Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.[
                                      "1_d_ptsd_exposure_aversive_details"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Intrusion Symptoms "}
                                  keyName="Intrusion Symptoms "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_ptsd_criterion_b"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Intrusive Memories"}
                                  keyName="Intrusive Memories"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_a_ptsd_intrusion_memories"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Traumatic Dreams"}
                                  keyName="Traumatic Dreams"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_b_ptsd_intrusion_dreams"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Flashbacks"}
                                  keyName="Flashbacks"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_c_ptsd_intrusion_flashbacks"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Psychological Distress"}
                                  keyName="Psychological Distress"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_d_ptsd_intrusion_distress"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Physiological Reactions"}
                                  keyName="Physiological Reactions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_e_ptsd_intrusion_reactions"] ||
                                    []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Avoidance Symptoms"}
                                  keyName="Avoidance Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_ptsd_criterion_c"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Thought Avoidance "}
                                  keyName="Thought Avoidance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_a_ptsd_avoidance_thoughts"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Reminder Avoidance"}
                                  keyName="Reminder Avoidance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_b_ptsd_avoidance_reminders"] ||
                                    []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Negative Cognitions and Mood "}
                                  keyName="Negative Cognitions and Mood"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_ptsd_criterion_d"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Amnesia"}
                                  keyName="Amnesia"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_a_ptsd_memory_loss"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Negative Beliefs"}
                                  keyName="Negative Beliefs"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_b_ptsd_negative_beliefs"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Self-Blame"}
                                  keyName="Self-Blame"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_c_ptsd_self_blame"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Negative Emotions"}
                                  keyName="Negative Emotions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_d_ptsd_emotional_state"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Interest Loss"}
                                  keyName="Interest Loss"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_e_ptsd_interest_loss"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Detachment "}
                                  keyName="Detachment "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_f_ptsd_detachment"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Positive Emotion Deficit"}
                                  keyName="Positive Emotion Deficit"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_g_ptsd_positive_emotions"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={
                                    "Alterations in Arousal and Reactivity"
                                  }
                                  keyName="Alterations in Arousal and Reactivity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_ptsd_criterion_e"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Irritability "}
                                  keyName="Irritability"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_a_ptsd_irritability"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Reckless Behavior"}
                                  keyName="Reckless Behavior"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_b_ptsd_reckless_behavior"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Hypervigilance "}
                                  keyName="Hypervigilance "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_c_ptsd_hypervigilance"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Startle Response"}
                                  keyName="Startle Response"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_d_ptsd_startle_response"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Concentration Issues"}
                                  keyName="Concentration Issues"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_e_ptsd_concentration"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Sleep Disturbance"}
                                  keyName="Sleep Disturbance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_f_ptsd_sleep_disturbance"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Duration of Symptoms"}
                                  keyName="Duration of Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["6_ptsd_duration"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Functional Impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["7_ptsd_impairment"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Exclusion of Other Causes"}
                                  keyName="Exclusion of Other Causes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["8_ptsd_exclusion"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          <span
                            style={{
                              ...sessionReportSummaryReportTitle,
                              display: dignosisSubthresholdList.length
                                ? "block"
                                : "none",
                            }}
                          >
                            References for Subthreshold Clinical Findings
                          </span>

                          {/* MDD */}
                          {dignosisSubthresholdList.includes("MDD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Major Depressive Disorder
                              </h3>
                              <ul>
                                <li style={{ fontWeight: "bold" }}>
                                  Symptoms:
                                </li>
                                <SubFormatArray
                                  subIndex={"depressed_mood"}
                                  keyName="Depressed Mood"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["1_mdd_mood"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"loss_of_interest_or_pleasure"}
                                  keyName="Loss of Interest or Pleasure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["2_mdd_interest"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "significant_weight_change_or_appetite_alteration"
                                  }
                                  keyName="Significant Weight Change or Appetite Alteration"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["3_mdd_weight"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"sleep_disturbances"}
                                  keyName="Sleep Disturbances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["4_mdd_insomnia"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "psychomotor_agitation_or_retardation"
                                  }
                                  keyName="Psychomotor Agitation or Retardation"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["5_mdd_retardation"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"fatigue_or_loss_of_energy"}
                                  keyName="Fatigue or Loss of Energy"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["6_mdd_fatigue"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "feelings_of_worthlessness_or_excessive_guilt"
                                  }
                                  keyName="Feelings of Worthlessness or Excessive Guilt"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["7_mdd_guilt"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "Diminished_ability_to_think _or_concentrate"
                                  }
                                  keyName="Diminished Ability to Think or Concentrate"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["8_mdd_concentration"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "Recurrent Thoughts of Death or Suicide"
                                  }
                                  keyName="Recurrent Thoughts of Death or Suicide"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["9_mdd_suicide"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"persistency_of_symptoms"}
                                  keyName="Persistency of Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["10_mdd_symptom_count"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"functional_impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["11_mdd_impairment"] || []
                                  }
                                />

                                <li style={{ fontWeight: "bold" }}>
                                  Exclusions:
                                </li>
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Substance Use, Medication, or Medical Condition"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["12_mdd_exclusion_medical"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "schizophrenia_spectrum_or_other_psychotic_disorders"
                                  }
                                  keyName="Schizophrenia Spectrum or Other Psychotic Disorders"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["13_mdd_exclusion_schizo"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "history_of_hypomanic_or_manic_Episodes"
                                  }
                                  keyName="History of Hypomanic or Manic Episodes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_mdd"
                                    )?.[1]?.["14_mdd_hypomanic"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* GAD */}
                          {dignosisSubthresholdList.includes("GAD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Generalized Anxiety Disorder
                              </h3>
                              <ul>
                                <li style={{ fontWeight: "bold" }}>
                                  Symptoms:
                                </li>
                                <SubFormatArray
                                  subIndex={"excessive_anxiety_orworry"}
                                  keyName="Excessive Anxiety or Worry"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["1_gad_symptom"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "difficulty_in_controlling_theworry"
                                  }
                                  keyName="Difficulty in Controlling the Worry"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["3_gad_control_of_worry"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Physical_or_cognitive_symptoms"}
                                  keyName="Physical or Cognitive Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["2_gad_physical_symptoms"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"functional_impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["4_gad_impairment"] || []
                                  }
                                />

                                <li style={{ fontWeight: "bold" }}>
                                  Exclusions:
                                </li>
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Panic Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5a_gad_panic_exclusion"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"social_anxiety_disorder"}
                                  keyName="Social Anxiety Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.[
                                      "5b_gad_social_anxiety_exclusion"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"obsessive_compulsive_disorder"}
                                  keyName="Obsessive-Compulsive Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5c_gad_ocd_exclusion"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={
                                    "substance_use_medication_or_medicalcondition"
                                  }
                                  keyName="Substance Use, Medication, or Medical Condition"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_gad"
                                    )?.[1]?.["5d_gad_medical_exclusion"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* SUD */}
                          {dignosisSubthresholdList.includes("SUD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Substance Use Disorder
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"1_dsm_control_usage"}
                                  keyName="Impaired Control - Usage"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["1_dsm_control_usage"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"2_dsm_control_desire"}
                                  keyName="Impaired Control - Desire To Cut Down"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["2_dsm_control_desire"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"3_dsm_control_commitment"}
                                  keyName="Impaired Control - Time Commitment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["3_dsm_control_commitment"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"4_dsm_control_arduous"}
                                  keyName="Impaired Control - Craving"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["4_dsm_control_arduous"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"5_dsm_social_failure"}
                                  keyName="Social Impairment - Failure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["5_dsm_social_failure"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"6_dsm_social_persistence"}
                                  keyName="Social Impairment - Persistence"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["6_dsm_social_persistence"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"7_dsm_social_decline"}
                                  keyName="Social Impairment - Decline"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["7_dsm_social_decline"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"8_dsm_risk_hazard"}
                                  keyName="Risky Use - Hazard"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["8_dsm_risk_hazard"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"9_dsm_risk_tenacity"}
                                  keyName="Risky Use - Tenacity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["9_dsm_risk_tenacity"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"10_dsm_tolerance"}
                                  keyName="Tolerance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["10_dsm_tolerance"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={
                                    "10_a_dsm_tolerance_dosage_increase"
                                  }
                                  keyName="Tolerance - Dosage Increase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.[
                                      "10_a_dsm_tolerance_dosage_increase"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_dsm_withdrawal"}
                                  keyName="Withdrawal"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_dsm_withdrawal"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_a_dsm_withdrawal_relief"}
                                  keyName="Withdrawal - Relief"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_a_dsm_withdrawal_relief"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"11_b_dsm_withdrawal_symptoms"}
                                  keyName="Withdrawal - Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sud"
                                    )?.[1]?.["11_b_dsm_withdrawal_symptoms"] ||
                                    []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* INSOMNIA */}
                          {dignosisSubthresholdList.includes("INSOMNIA") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>Insomnia</h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Sleep Difficulties"}
                                  keyName="Sleep Difficulties"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["1_insomnia_criterion_a"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Initiating Sleep"}
                                  keyName="Initiating Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_a_insomnia_difficulty_initiating_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Maintaining Sleep"}
                                  keyName="Maintaining Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_b_insomnia_difficulty_maintaining_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Early Awakening"}
                                  keyName="Early Awakening"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "1_c_insomnia_early_morning_awakening"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "2_insomnia_distress_impairment"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Frequency"}
                                  keyName="Frequency"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["3_insomnia_frequency"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Duration"}
                                  keyName="Duration"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["4_insomnia_duration"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Adequate Opportunity"}
                                  keyName="Adequate Opportunity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["5_insomnia_opportunity"] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Exclusions"}
                                  keyName="Exclusions"
                                  value={" "}
                                />
                                <SubFormatArray
                                  subIndex={"Other Sleep-Wake Disorder"}
                                  keyName="Other Sleep-Wake Disorder"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "6_insomnia_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Substances"}
                                  keyName="Substances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "7_insomnia_exclusion_substances"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Medical/Mental"}
                                  keyName="Medical/Mental"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "8_insomnia_exclusion_medical_mental"
                                    ] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* HYPERSOMNOLENCE */}
                          {dignosisSubthresholdList.includes(
                            "HYPERSOMNOLENCE"
                          ) && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Hypersomnolence
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Excessive Sleepiness"}
                                  keyName="Excessive Sleepiness"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["9_hypersomnolence_criterion_a"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Recurrent Sleep Episodes"}
                                  keyName="Recurrent Sleep Episodes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_a_hypersomnolence_recurrent_sleep_periods"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Prolonged Sleep"}
                                  keyName="Prolonged Sleep"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_b_hypersomnolence_prolonged_sleep"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Awakening Difficulty"}
                                  keyName="Awakening Difficulty"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "9_c_hypersomnolence_difficulty_awakening"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Frequency"}
                                  keyName="Frequency"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["10_hypersomnolence_frequency"] ||
                                    []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "11_hypersomnolence_distress_impairment"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Exclusions:"}
                                  keyName="Exclusions"
                                  value={" "}
                                />
                                <SubFormatArray
                                  subIndex={"Other Sleep-Awake Disorders"}
                                  keyName="Other Sleep-Awake Disorders"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "12_hypersomnolence_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Substances"}
                                  keyName="Substances"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "13_hypersomnolence_exclusion_substances"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Medical/Mental"}
                                  keyName="Medical/Mental"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "14_hypersomnolence_exclusion_medical_mental"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Severity"}
                                  keyName="Severity"
                                  value={""}
                                />
                                <SubFormatArray
                                  subIndex={"Mild"}
                                  keyName="Mild"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_a_hypersomnolence_severity_mild"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Moderate"}
                                  keyName="Moderate"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_b_hypersomnolence_severity_moderate"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Severe"}
                                  keyName="Severe"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "15_c_hypersomnolence_severity_severe"
                                    ] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/*  Circadian Rhythm Sleep-Wake Disorder  */}
                          {dignosisSubthresholdList.includes("CRSWD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Circadian Rhythm Sleep-Wake Disorder
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Circadian Disruption"}
                                  keyName="Circadian Disruption"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["16_circadian_disruption"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Sleepiness/Insomnia"}
                                  keyName="Sleepiness/Insomnia"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "17_circadian_sleepiness_insomnia"
                                    ] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Distress/Impairment"}
                                  keyName="Distress/Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "18_circadian_distress_impairment"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={
                                    "Exclusion of other medical or mental conditions"
                                  }
                                  keyName="Exclusion of other medical or mental conditions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "19_circadian_exclusion_other_disorders"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Subtype"}
                                  keyName="Subtype"
                                  value=""
                                />
                                <SubFormatArray
                                  subIndex={"Delayed Sleep Phase"}
                                  keyName="Delayed Sleep Phase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_a_circadian_delayed_sleep_phase"
                                    ] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Advanced Sleep Phase"}
                                  keyName="Advanced Sleep Phase"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_b_circadian_advanced_sleep_phase"
                                    ] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Irregular Sleep-Wake"}
                                  keyName="Irregular Sleep-Wake"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.[
                                      "20_c_circadian_irregular_sleep_wake"
                                    ] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Non-24-Hour Sleep-Wake"}
                                  keyName="Non-24-Hour Sleep-Wake"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_d_circadian_non_24_hour"] || []
                                  }
                                />

                                <SubFormatArray
                                  subIndex={"Shift Work "}
                                  keyName="Shift Work"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_e_circadian_shift_work"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Unspecified"}
                                  keyName="Unspecified"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_sleep"
                                    )?.[1]?.["20_f_circadian_unspecified"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}

                          {/* PTSD */}
                          {dignosisSubthresholdList.includes("PTSD") && (
                            <>
                              <h3 style={{ marginLeft: "20px" }}>
                                Post-Traumatic Stress Disorder (PTSD){" "}
                              </h3>
                              <ul>
                                <FormatArray
                                  subIndex={"Exposure to Traumatic Event(s)"}
                                  keyName="Exposure to Traumatic Event(s)"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_ptsd_criterion_a"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Direct Exposure"}
                                  keyName="Direct Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_a_ptsd_exposure_direct"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Witnessed Exposure"}
                                  keyName="Witnessed Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_b_ptsd_exposure_witnessing"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"delayed_xxpression"}
                                  keyName="Learned Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["1_c_ptsd_exposure_learning"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Aversive Details Exposure"}
                                  keyName="Aversive Details Exposure"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.[
                                      "1_d_ptsd_exposure_aversive_details"
                                    ] || []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Intrusion Symptoms "}
                                  keyName="Intrusion Symptoms "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_ptsd_criterion_b"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Intrusive Memories"}
                                  keyName="Intrusive Memories"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_a_ptsd_intrusion_memories"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Traumatic Dreams"}
                                  keyName="Traumatic Dreams"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_b_ptsd_intrusion_dreams"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Flashbacks"}
                                  keyName="Flashbacks"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_c_ptsd_intrusion_flashbacks"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Psychological Distress"}
                                  keyName="Psychological Distress"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_d_ptsd_intrusion_distress"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Physiological Reactions"}
                                  keyName="Physiological Reactions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["2_e_ptsd_intrusion_reactions"] ||
                                    []
                                  }
                                />
                                <FormatArray
                                  subIndex={"Avoidance Symptoms"}
                                  keyName="Avoidance Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_ptsd_criterion_c"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Thought Avoidance "}
                                  keyName="Thought Avoidance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_a_ptsd_avoidance_thoughts"] ||
                                    []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Reminder Avoidance"}
                                  keyName="Reminder Avoidance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["3_b_ptsd_avoidance_reminders"] ||
                                    []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Negative Cognitions and Mood "}
                                  keyName="Negative Cognitions and Mood"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_ptsd_criterion_d"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Amnesia"}
                                  keyName="Amnesia"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_a_ptsd_memory_loss"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Negative Beliefs"}
                                  keyName="Negative Beliefs"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_b_ptsd_negative_beliefs"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Self-Blame"}
                                  keyName="Self-Blame"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_c_ptsd_self_blame"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Negative Emotions"}
                                  keyName="Negative Emotions"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_d_ptsd_emotional_state"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Interest Loss"}
                                  keyName="Interest Loss"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_e_ptsd_interest_loss"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Detachment "}
                                  keyName="Detachment "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_f_ptsd_detachment"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Positive Emotion Deficit"}
                                  keyName="Positive Emotion Deficit"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["4_g_ptsd_positive_emotions"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={
                                    "Alterations in Arousal and Reactivity"
                                  }
                                  keyName="Alterations in Arousal and Reactivity"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_ptsd_criterion_e"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Irritability "}
                                  keyName="Irritability"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_a_ptsd_irritability"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Reckless Behavior"}
                                  keyName="Reckless Behavior"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_b_ptsd_reckless_behavior"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Hypervigilance "}
                                  keyName="Hypervigilance "
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_c_ptsd_hypervigilance"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Startle Response"}
                                  keyName="Startle Response"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_d_ptsd_startle_response"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Concentration Issues"}
                                  keyName="Concentration Issues"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_e_ptsd_concentration"] || []
                                  }
                                />
                                <SubFormatArray
                                  subIndex={"Sleep Disturbance"}
                                  keyName="Sleep Disturbance"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["5_f_ptsd_sleep_disturbance"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Duration of Symptoms"}
                                  keyName="Duration of Symptoms"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["6_ptsd_duration"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Functional Impairment"}
                                  keyName="Functional Impairment"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["7_ptsd_impairment"] || []
                                  }
                                />

                                <FormatArray
                                  subIndex={"Exclusion of Other Causes"}
                                  keyName="Exclusion of Other Causes"
                                  value={
                                    sessionSummaryListData.find(
                                      ([key]) => key === "summary_ptsd"
                                    )?.[1]?.["8_ptsd_exclusion"] || []
                                  }
                                />
                              </ul>
                            </>
                          )}
                        </>
                      )}

                    {diagnosisLoading && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}
                    {!diagnosisLoading &&
                      dignosisPrincipalList.length === 0 &&
                      dignosisSubthresholdList.length === 0 && (
                        <>
                          <span style={sessionReportTitle}>
                            Assessment Recommendation Summary
                          </span>
                          <ul>
                            <li key="Principal Clinical Findings">
                              <strong>Principal Clinical Findings:</strong>
                            </li>
                            <FormatArray
                              subIndex={"code"}
                              keyName="ICD-10 CM code"
                              value=" "
                            />
                          </ul>
                          <span style={sessionReportTitle}>
                            Assessment Recommendation Details
                          </span>

                          <h3 style={{ marginLeft: "20px" }}>
                            Substance Use Disorder
                          </h3>
                          <ul>
                            <li key="Principal Clinical Findings">
                              <strong>Principal Clinical Findings:</strong>
                            </li>
                            <FormatArray
                              subIndex={"code"}
                              keyName="ICD-10 CM code"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"substance"}
                              keyName="Main Substance"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"secondary_diagnosis"}
                              keyName="Other Substances"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"severity"}
                              keyName="Severity"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"substance"}
                              keyName="Frameworks"
                              value=" "
                            />
                          </ul>
                          <span
                            style={{
                              ...sessionReportTitle,
                            }}
                          >
                            References for Clinical Findings
                          </span>
                          <span
                            style={{
                              ...sessionReportTitle,
                              backgroundColor: "#faf7dc",
                              marginTop: "10px",
                            }}
                          >
                            References for Principal Clinical Findings
                          </span>

                          <h3 style={{ marginLeft: "20px" }}>
                            Substance Use Disorder
                          </h3>
                          <ul>
                            <FormatArray
                              subIndex={"1_dsm_control_usage"}
                              keyName="Impaired Control - Usage"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"2_dsm_control_desire"}
                              keyName="Impaired Control - Desire To Cut Down"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"3_dsm_control_commitment"}
                              keyName="Impaired Control - Time Commitment"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"4_dsm_control_arduous"}
                              keyName="Impaired Control - Craving"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"5_dsm_social_failure"}
                              keyName="Social Impairment - Failure"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"6_dsm_social_persistence"}
                              keyName="Social Impairment - Persistence"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"7_dsm_social_decline"}
                              keyName="Social Impairment - Decline"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"8_dsm_risk_hazard"}
                              keyName="Risky Use - Hazard"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"9_dsm_risk_tenacity"}
                              keyName="Risky Use - Tenacity"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"10_dsm_tolerance"}
                              keyName="Tolerance"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"10_a_dsm_tolerance_dosage_increase"}
                              keyName="Tolerance - Dosage Increase"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"11_dsm_withdrawal"}
                              keyName="Withdrawal"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"11_a_dsm_withdrawal_relief"}
                              keyName="Withdrawal - Relief"
                              value=" "
                            />
                            <FormatArray
                              subIndex={"11_b_dsm_withdrawal_symptoms"}
                              keyName="Withdrawal - Symptoms"
                              value=" "
                            />
                          </ul>
                        </>
                      )}
                    {/* {diagnosisLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )} */}
                  </Typography>
                </Box>

                {!diagnosisLoading &&
                  !diagnosisIsExpanded &&
                  diagnosisList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("diagosis")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {diagnosisIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("aida_dignosis")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {diagnosisIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* Session Transcript */}
        {!sessionSummaryIsExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionTranscriptisExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionTranscriptisExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Transcript
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionTranscript}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>

                  {!sessionTranscriptLoading &&
                    !sessionTranscriptList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}

                  {!sessionTranscriptLoading && (
                    <Typography
                      variant="body2"
                      component="div"
                      id="sessionTranscriptDiv"
                      sx={{
                        flex: 1, // Makes it fill the available space
                        minHeight: "150px",
                        maxHeight: sessionTranscriptisExpanded
                          ? "300px"
                          : "50px", // Corrected to "50px"
                        overflowY: sessionTranscriptisExpanded
                          ? "auto"
                          : "hidden",
                        transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                        overflowX: "hidden",
                      }}
                    >
                      {sessionTranscriptList.map((item, i) => (
                        <div key={i}>
                          <strong style={{ justifyContent: "flex-end" }}>
                            {item.speaker}:
                          </strong>{" "}
                          {item.text}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  )}

                  {sessionTranscriptLoading && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ mt: 3 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>

                {!sessionTranscriptLoading &&
                  !sessionTranscriptisExpanded &&
                  sessionTranscriptList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_transcript")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionTranscriptisExpanded
                          ? "View Less"
                          : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_transcript")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionTranscriptisExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Treatment Plan Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !diagnosisIsExpanded && (
            <Grid item xs={12} md={treatmentIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: treatmentIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",

                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%", // Makes the button rounded
                        // border: "1px solid black",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Recommendations for Supportive Actions
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionAIDATreatmentPlan}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionTreatmentPlanDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: treatmentIsExpanded ? "300px" : "50px", // Expand/Collapse max height
                      overflowY: treatmentIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {!diagnosisLoading && diagnosisList.length > 0 && (
                      <>
                        <span style={sessionReportTitle}>
                          Supportive Care Plan Summary
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"pharmacotherapy"}
                            keyName="Medication"
                            value={treatmentDetailInfo.pharmacotherapy}
                          />
                          <FormatArray
                            subIndex={"psychotherapy"}
                            keyName="Therapy"
                            value={treatmentDetailInfo.psychotherapy}
                          />
                        </ul>
                        <span style={sessionReportTitle}>Pharmacological</span>
                        <Box height={10}></Box>
                        <TreatmentPlanPharmacologicalTable
                          data={pharmacologicalMedicationList}
                        />
                        <Box height={10}></Box>
                        <span style={sessionReportTitle}>
                          Non-Pharmacological
                        </span>
                        <Box height={10}></Box>
                        <TreatmentPlanNonPharmacologicalTable
                          data={nonPharmacologicalMedicationList}
                        />
                      </>
                    )}
                    {!treatmentLoading && !treatmentPlanList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}
                    {treatmentLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!treatmentLoading &&
                  !treatmentIsExpanded &&
                  !sessionTranscriptisExpanded &&
                  treatmentPlanList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("treatment_plan")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {treatmentIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() =>
                          downloadTxtFile("session_treatment_plan")
                        }
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {treatmentIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}
      </Grid>
    </AppDrawer>
  );
};

export default SessionReport;
